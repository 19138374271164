import React, {useEffect, useState} from "react";
import {RosterGrid} from "../components/RosterGrid";
import {apiGet, apiPost, getUserName, rosterGridTypes} from "../utils";
import Grid from "@material-ui/core/Grid";
import {DropPlayerDialog} from "../components/DropPlayerDialog";
import {useMediaQuery} from "@mui/material";

export const HomeContainer = () => {
    const [currentAuction, setCurrentAuction] = useState({});
    const [dropDialogOpen, setDropDialogOpen] = useState(false);
    const [dropRow, setDropRow] = useState({});

    const isMobile = useMediaQuery('(max-width: 780px)');

    const fetchCurrentAuction = () => {
        apiGet(`auction/current`, (data) => {
            setCurrentAuction(data.data ? data.data : {});
        });
    };

    useEffect(() => {
        fetchCurrentAuction();
    }, []);

    const handleOpenDropDialog = (row) => {
        setDropRow(row);
        setDropDialogOpen(true);
    };

    const handleCloseDropDialog = () => {
        setDropDialogOpen(false);
    };

    const handleDropPlayer = () => {
        setDropDialogOpen(false);
        const dropPostBody = {
            playerYahooId: dropRow.yahoo_id,
            managerName: getUserName()
        }
        //TODO: conditionally call drop/predraft if applicable...
        apiPost(`contract/drop`, dropPostBody, () => {
            fetchCurrentAuction();
        });
    };

    const justify = isMobile ? 'flex-start' : 'center';
    return (
        <div style={{marginTop: 50}}>
            <Grid container justifyContent={justify}>
                <RosterGrid type={rosterGridTypes.ACTIONS} managerName={getUserName()}
                            seasonYear={currentAuction.season_year} weekNum={currentAuction.week_num}
                            currentAuction={currentAuction}
                            onDropButtonClick={handleOpenDropDialog}/>
                <DropPlayerDialog open={dropDialogOpen} handleClose={handleCloseDropDialog} dropRow={dropRow}
                                  handleDropPlayer={handleDropPlayer}/>
            </Grid>
        </div>
    );
}