import React from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";

export const PaymentHistoryTable = ({paymentHistory}) => {
    if (paymentHistory) {
        const tableRows = [];
        let currentContractKey;
        let numPaymentsUnderContract;
        let contractCursor = 0;
        let newContractRow;
        paymentHistory.forEach((payment, idx) => {
            if (payment.contract_key !== currentContractKey) {
                currentContractKey = payment.contract_key;
                numPaymentsUnderContract = paymentHistory.filter(p => p.contract_key === currentContractKey).length;
                contractCursor++;
                newContractRow = true;
            }
            tableRows.push(<TableRow key={payment.id}>
                {newContractRow &&
                    <TableCell rowSpan={numPaymentsUnderContract}
                               style={{verticalAlign: 'top'}}>{contractCursor}</TableCell>
                }
                <TableCell>{idx + 1}</TableCell>
                <TableCell>{payment.manager_name}</TableCell>
                <TableCell>{payment.start_season_year} {payment.start_auction_is_draft ? 'Draft' : `Week ${payment.start_week_num}`}</TableCell>
                <TableCell>{payment.end_auction_key ? `${payment.end_season_year} ${payment.end_auction_is_draft ? 'Draft' : 'Week ' + payment.end_week_num}` : ''}</TableCell>
                <TableCell style={{textAlign: "right"}}>{payment.is_penalty ? '' : payment.amount}</TableCell>
                <TableCell style={{textAlign: "right"}}>{payment.is_penalty ? payment.amount : ''}</TableCell>
            </TableRow>);
            newContractRow = false;
        })
        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Contract</TableCell>
                            <TableCell>Payment</TableCell>
                            <TableCell>Manager</TableCell>
                            <TableCell>Start</TableCell>
                            <TableCell>End</TableCell>
                            <TableCell>Salary</TableCell>
                            <TableCell>Penalty</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableRows}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    } else {
        return (
            <Typography variant="body1" color="inherit" style={{marginLeft: 15, paddingTop: 20}}>
                No payment history found
            </Typography>
        )
    }
}