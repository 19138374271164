import React, {useState} from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import {config} from "../config";
import {Checkbox, IconButton, ListItemText, Select} from "@material-ui/core";
import {RotateLeft} from "@material-ui/icons";

export const ManagerPickerMultiselect = ({fetchFunction}) => {
    const [selectedNames, setSelectedNames] = useState([]);

    const handleChange = (event) => {
        const {target: {value}} = event;
        if (value.includes('selectAll')) {
            setSelectedNames(selectedNames.length === config.managerNames.length ? [] : config.managerNames);
        } else {
            setSelectedNames(typeof value === 'string' ? value.split(',') : value);
        }
    };

    const handleReset = () => {
        setSelectedNames([]);
    };

    const handleSubmit = () => {
        fetchFunction(selectedNames);
    };

    return (
        <div style={{paddingTop: 15, paddingBottom: 15}}>
            Managers:
            <Select multiple value={selectedNames} onChange={handleChange}
                    style={{width: 110, height: 25, marginLeft: 10, marginRight: 10, backgroundColor: "lightgray"}}
                    renderValue={(selected) => `${selected.length} selected`}
                    MenuProps={{getContentAnchorEl: null}}>
                <MenuItem key="selectAll" value="selectAll" style={{height: 36}}>
                    <Checkbox checked={selectedNames.length === config.managerNames.length}
                              indeterminate={selectedNames.length > 0 && selectedNames.length < config.managerNames.length}
                              style={{height: 36}}/>
                    <ListItemText primary="(All)" style={{height: 36, marginTop: 16}}/>
                </MenuItem>
                {config.managerNames.map((name) => (
                    <MenuItem key={name} value={name} style={{height: 36}}>
                        <Checkbox checked={selectedNames.includes(name)} style={{height: 36}}/>
                        <ListItemText primary={name} style={{height: 36, marginTop: 16}}/>
                    </MenuItem>
                ))}
            </Select>
            <Button variant="contained" color="primary" style={{height: 25}} onClick={handleSubmit}>Select</Button>
            <IconButton color="inherit" style={{height: 25}} onClick={handleReset}>
                <RotateLeft/>
            </IconButton>
        </div>
    );
}