import React, {useState, useEffect, useCallback} from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import {config} from "../config";
import {IconButton} from "@material-ui/core";
import {NavigateBefore, NavigateNext, RotateLeft} from "@material-ui/icons";

const array1to16 = Array(16).fill().map((_, idx) => idx + 1);

export const WeekPicker = ({setterFunction, currentAuction, includeDraft = true, includeWeek17 = true, includeCurrent = true, includeFuture = false, weekOnlyMode = false}) => {
    const [weekNums, setWeekNums] = useState([...array1to16]);
    const [anchorElSeason, setAnchorElSeason] = useState(null);
    const [anchorElWeek, setAnchorElWeek] = useState(null);
    const [selectedSeasonIndex, setSelectedSeasonIndex] = useState(null);
    const [selectedWeekIndex, setSelectedWeekIndex] = useState(null);

    const initialWeekNum = useCallback(() => {
        return includeCurrent ? currentAuction.week_num : currentAuction.week_num - 1;
    }, [currentAuction.week_num, includeCurrent]);

    useEffect(() => {
        let nums = [...array1to16];
        if (includeDraft) nums = [0, ...nums];
        if (includeWeek17) nums = [...nums, 17];
        setWeekNums(nums);
    }, [includeDraft, includeWeek17]);

    useEffect(() => {
        //TODO: just call handleReset...??
        setSelectedSeasonIndex(config.seasonYears.indexOf(currentAuction.season_year));
        setSelectedWeekIndex(weekNums.indexOf(initialWeekNum()));
    }, [currentAuction.season_year, weekNums, initialWeekNum]);

    const handleClickSeason = (event) => {
        setAnchorElSeason(event.currentTarget);
    };

    const handleClickWeek = (event) => {
        setAnchorElWeek(event.currentTarget);
    };

    const handleSeasonMenuItemClick = (event, index) => {
        setSelectedSeasonIndex(index);
        setAnchorElSeason(null);
    };

    const handleWeekMenuItemClick = (event, index) => {
        if (weekOnlyMode) {
            setterFunction(weekNums[index]);
        }
        setSelectedWeekIndex(index);
        setAnchorElWeek(null);
    };

    const handleClose = () => {
        setAnchorElSeason(null);
        setAnchorElWeek(null);
    };

    const handlePreviousWeek = () => {
        if (!(selectedSeasonIndex === 0 && selectedWeekIndex === 0)) {
            if (selectedWeekIndex === 0) {
                setterFunction(config.seasonYears[selectedSeasonIndex - 1], weekNums[weekNums.length - 1]);
                setSelectedWeekIndex(weekNums.length - 1);
                setSelectedSeasonIndex(selectedSeasonIndex - 1);
            } else {
                setterFunction(config.seasonYears[selectedSeasonIndex], weekNums[selectedWeekIndex - 1]);
                setSelectedWeekIndex(selectedWeekIndex - 1);
            }
        }
    };

    const handleNextWeek = () => {
        if (!weekNumIsDisabled(weekNums[selectedWeekIndex + 1])) { //TODO... avoid out of bounds error?
            if (selectedWeekIndex === weekNums.length - 1) {
                setterFunction(config.seasonYears[selectedSeasonIndex + 1], weekNums[0]);
                setSelectedWeekIndex(0);
                setSelectedSeasonIndex(selectedSeasonIndex + 1);
            } else {
                setterFunction(config.seasonYears[selectedSeasonIndex], weekNums[selectedWeekIndex + 1]);
                setSelectedWeekIndex(selectedWeekIndex + 1);
            }
        }
    };

    const handleSubmit = () => {
        if (!weekNumIsDisabled(weekNums[selectedWeekIndex])) {
            if (weekOnlyMode) {
                setterFunction(weekNums[selectedWeekIndex]);
            } else {
                setterFunction(config.seasonYears[selectedSeasonIndex], weekNums[selectedWeekIndex]);
            }
        }
    };

    const handleReset = () => {
        setSelectedSeasonIndex(config.seasonYears.indexOf(currentAuction.season_year));
        setSelectedWeekIndex(weekNums.indexOf(initialWeekNum()));
        setterFunction(currentAuction.season_year, initialWeekNum());
    };

    const weekNumIsCurrent = (num) => {
        return (selectedSeasonIndex === config.seasonYears.indexOf(currentAuction.season_year) && num === currentAuction.week_num);
    };

    const weekNumIsFuture = (num) => {
        return (selectedSeasonIndex === config.seasonYears.indexOf(currentAuction.season_year) && num > currentAuction.week_num);
    };

    const weekNumIsDisabled = (num) => {
        return (num === 0 && !includeDraft) || (num === 17 && !includeWeek17) || (weekNumIsCurrent(num) && !includeCurrent) || (weekNumIsFuture(num) && !includeFuture);
    };

    return (
        <div style={{paddingTop: 15, paddingBottom: 15}}>
            {!weekOnlyMode &&
                <div style={{display: "inline"}}>
                    <IconButton color="inherit" style={{height: 25}} onClick={handlePreviousWeek}>
                        <NavigateBefore/>
                    </IconButton>
                    <IconButton color="inherit" style={{height: 25}} onClick={handleNextWeek}>
                        <NavigateNext/>
                    </IconButton>
                    Season:
                    <Button style={{backgroundColor: "lightgray", height: 25, marginLeft: 10, marginRight: 10}}
                            onClick={handleClickSeason}>{config.seasonYears[selectedSeasonIndex]}</Button>
                    <Menu id="seasonPickerMenu" anchorEl={anchorElSeason} keepMounted open={Boolean(anchorElSeason)}
                          onClose={handleClose}>
                        {config.seasonYears.map((seasonYear, index) => (
                            <MenuItem key={seasonYear} selected={index === selectedSeasonIndex}
                                      onClick={(event) => handleSeasonMenuItemClick(event, index)}>{seasonYear}</MenuItem>
                        ))}
                    </Menu>
                </div>
            }
            Week:
            <Button style={{backgroundColor: "lightgray", height: 25, marginLeft: 10, marginRight: 10}}
                    onClick={handleClickWeek}>{weekNums[selectedWeekIndex]}</Button>
            <Menu id="weekPickerMenu" anchorEl={anchorElWeek} keepMounted open={Boolean(anchorElWeek)}
                  onClose={handleClose}>
                {weekNums.map((weekNum, index) => (
                    <MenuItem key={weekNum} value={weekNum} selected={index === selectedWeekIndex}
                              disabled={weekNumIsDisabled(weekNum)}
                              onClick={(event) => handleWeekMenuItemClick(event, index)}>{weekNum === 0 ? 'Draft' : weekNum}</MenuItem>
                ))}
            </Menu>
            {!weekOnlyMode &&
                <div style={{display: "inline"}}>
                    <Button variant="contained" color="primary" style={{height: 25}}
                            onClick={handleSubmit}>Select</Button>
                    <IconButton color="inherit" style={{height: 25}} onClick={handleReset}>
                        <RotateLeft/>
                    </IconButton>
                </div>
            }
        </div>
    );
}