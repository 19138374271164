import React from 'react';
import {LineChart} from "@mui/x-charts";

//TODO: use CSS classes for manager colors...
const colors = [
    '#4e79a7',
    '#f28e2c',
    '#e15759',
    '#76b7b2',
    '#59a14f',
    '#edc949',
    '#af7aa1',
    '#ff9da7',
    '#9c755f',
    '#bab0ab',
];

const buildManagerList = (picks) => {
    return [...new Set(picks.map(p => p.manager_name))].sort();
};

const buildXLabels = (seriesMaps) => {
    const numWeeks = Math.max(...seriesMaps.map(s => s.data.length));
    const labels = [];
    for (let w = 1; w <= numWeeks; w++) {
        labels.push(`Week ${w}`);
    }
    return labels;
};

const buildWeeklyCorrectCounts = (managerList, picks, gameResults) => {
    const weeklyCorrectCounts = {};
    let maxWeekNumPlayed = 0;
    for (const manager of managerList) {
        weeklyCorrectCounts[manager] = [];
        for (const pick of picks) {
            if (pick.manager_name === manager) {
                const teamResults = gameResults[pick.team_abbr];
                const weekNumsPlayed = Object.keys(teamResults).map(n => parseInt(n));
                const lastWeekNumPlayed = Math.max(...weekNumsPlayed);
                maxWeekNumPlayed = Math.max(maxWeekNumPlayed, lastWeekNumPlayed);
                for (let w = 1; w <= lastWeekNumPlayed; w++) {
                    if (weeklyCorrectCounts[manager].length < w) weeklyCorrectCounts[manager].push(0);
                    if (weekNumsPlayed.includes(w)) {
                        if ((pick.wins && teamResults[`${w}`] === 'W') || (!pick.wins && teamResults[`${w}`] === 'L')) {
                            weeklyCorrectCounts[manager][w - 1] += 1;
                        }
                    }
                }
            }
        }
    }
    for (const manager of managerList) {
        for (let w = 2; w <= maxWeekNumPlayed; w++) {
            weeklyCorrectCounts[manager][w - 1] = weeklyCorrectCounts[manager][w - 1] + weeklyCorrectCounts[manager][w - 2];
        }
    }
    const usedSpots = {};
    for (const manager of managerList) {
        for (let w = 1; w <= maxWeekNumPlayed; w++) {
            if (!usedSpots[w]) usedSpots[w] = [];
            let stored = false;
            while (!stored) {
                if (usedSpots[w].includes(weeklyCorrectCounts[manager][w - 1])) {
                    weeklyCorrectCounts[manager][w - 1] += 0.05;
                } else {
                    usedSpots[w].push(weeklyCorrectCounts[manager][w - 1]);
                    stored = true;
                }
            }
        }
    }
    return weeklyCorrectCounts;
};

const buildSeriesMaps = (managerList, weeklyCorrectCounts) => {
    const seriesMaps = [];
    for (const manager of managerList) {
        seriesMaps.push({
            label: manager, data: weeklyCorrectCounts[manager], color: colors[managerList.indexOf(manager)],
            valueFormatter: (v) => Math.floor(v)
        });
    }
    return seriesMaps;
};

export const WinsPoolChart = ({picks, gameResults}) => {
    if (picks.length > 0 && Object.keys(gameResults).length > 0) {
        const managerList = buildManagerList(picks);
        const weeklyCorrectCounts = buildWeeklyCorrectCounts(managerList, picks, gameResults);
        const seriesMaps = buildSeriesMaps(managerList, weeklyCorrectCounts);
        const xLabels = buildXLabels(seriesMaps);
        return (
            <div style={{backgroundColor: '#F2F2F2'}}>
                <LineChart
                    xAxis={[{scaleType: 'point', data: xLabels}]}
                    yAxis={[{tickMinStep: 1, tickMaxStep: 1}]}
                    series={seriesMaps}
                    width={1280}
                    height={1000}
                />
            </div>
        );
    } else {
        return (<div/>);
    }
}
