import React, {useMemo} from 'react';
import {DataGrid} from "@mui/x-data-grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import {getUserName} from "../utils";

export const SeasonResultsGrid = ({rows, loading, isAllTimeSummary = false}) => {

    const columnModels = useMemo(() => {
        const colDefs = {
            seasonResultColumns: [
                {hideable: false, headerClassName: 'grid-header', width: 115, headerName: 'Final Rank', field: 'final_rank'},
                {hideable: false, headerClassName: 'grid-header', width: 185, headerName: 'Regular Season Rank', field: 'reg_season_rank'},
                {hideable: false, headerClassName: 'grid-header', width: 105, headerName: 'Manager', field: 'manager_name'},
                {hideable: false, headerClassName: 'grid-header', width: 80, headerName: 'Wins', field: 'wins', type: 'number', headerAlign: 'right'},
                {hideable: false, headerClassName: 'grid-header', width: 95, headerName: 'Losses', field: 'losses', type: 'number', headerAlign: 'right'},
                {hideable: false, headerClassName: 'grid-header', width: 75, headerName: 'Ties', field: 'ties', type: 'number', headerAlign: 'right'},
                {hideable: false, headerClassName: 'grid-header', width: 195, headerName: 'Regular Season Points', field: 'reg_season_points', type: 'number', headerAlign: 'right'},
                {hideable: false, headerClassName: 'grid-header', width: 245, headerName: 'Regular Season Points Against', field: 'reg_season_points_against', type: 'number', headerAlign: 'right'},
                {hideable: false, headerClassName: 'grid-header', width: 110, headerName: 'Semi Win', field: 'semi_win', type: 'number', headerAlign: 'right'},
                {hideable: false, headerClassName: 'grid-header', width: 105, headerName: 'Final Win', field: 'final_win', type: 'number', headerAlign: 'right'},
                {hideable: false, headerClassName: 'grid-header', width: 120, headerName: 'Total Points', field: 'total_points', type: 'number', headerAlign: 'right'},
                {hideable: false, headerClassName: 'grid-header', width: 175, headerName: 'Total Points Against', field: 'total_points_against', type: 'number', headerAlign: 'right'}
            ],
            allTimeColumns: [
                {hideable: false, headerClassName: 'grid-header', width: 105, headerName: 'Manager', field: 'manager_name'},
                {hideable: false, headerClassName: 'grid-header', width: 145, headerName: 'Avg Final Rank', field: 'avg_final_rank'},
                {hideable: false, headerClassName: 'grid-header', width: 215, headerName: 'Avg Regular Season Rank', field: 'avg_reg_season_rank'},
                {hideable: false, headerClassName: 'grid-header', width: 80, headerName: 'Wins', field: 'sum_wins', type: 'number', headerAlign: 'right'},
                {hideable: false, headerClassName: 'grid-header', width: 95, headerName: 'Losses', field: 'sum_losses', type: 'number', headerAlign: 'right'},
                {hideable: false, headerClassName: 'grid-header', width: 75, headerName: 'Ties', field: 'sum_ties', type: 'number', headerAlign: 'right'},
                {hideable: false, headerClassName: 'grid-header', width: 195, headerName: 'Regular Season Points', field: 'sum_reg_season_points', type: 'number', headerAlign: 'right'},
                {hideable: false, headerClassName: 'grid-header', width: 245, headerName: 'Regular Season Points Against', field: 'sum_reg_season_points_against', type: 'number', headerAlign: 'right'},
                {hideable: false, headerClassName: 'grid-header', width: 110, headerName: 'Semi Win', field: 'sum_semi_win', type: 'number', headerAlign: 'right'},
                {hideable: false, headerClassName: 'grid-header', width: 105, headerName: 'Final Win', field: 'sum_final_win', type: 'number', headerAlign: 'right'},
                {hideable: false, headerClassName: 'grid-header', width: 120, headerName: 'Total Points', field: 'sum_total_points', type: 'number', headerAlign: 'right'},
                {hideable: false, headerClassName: 'grid-header', width: 175, headerName: 'Total Points Against', field: 'sum_total_points_against', type: 'number', headerAlign: 'right'}
            ]
        };

        const calcWidth = (cols) => {
            return cols.reduce((t, c) => t + c.width, 0) + 1;
        };

        const defaultModel = [...colDefs.seasonResultColumns];
        const summaryModel = [...colDefs.allTimeColumns];

        return {
            default: {cols: defaultModel, width: calcWidth(defaultModel)},
            summary: {cols: summaryModel, width: calcWidth(summaryModel)}
        };
    }, []);

    const displayColumns = useMemo(() => {
        return isAllTimeSummary ? columnModels.summary : columnModels.default;
    }, [columnModels, isAllTimeSummary]);

    const determineRowClass = (params) => {
        return params.row.manager_name === getUserName() ? 'is-self' : '';
    };

    return (
        <div style={{width: displayColumns.width, marginLeft: 10, marginRight: 10}}>
            {loading && <LinearProgress color="secondary"/>}
            <DataGrid columns={displayColumns.cols} rows={rows} hideFooter={true}
                      columnHeaderHeight={30} rowHeight={23} autoHeight
                      getRowClassName={(params) => (determineRowClass(params))}
                      disableRowSelectionOnClick disableMultipleRowSelection disableColumnResize/>
        </div>
    );
}
