import React, {useCallback, useEffect, useState} from "react";
import {apiGet, apiPost} from "../utils";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import {IconButton, TextField, Typography} from "@material-ui/core";
import {RotateLeft} from "@material-ui/icons";
import {useMediaQuery} from "@mui/material";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {WeekPicker} from "../components/WeekPicker";

export const AdminContainer = () => {
    const [dbMeta, setDbMeta] = useState({});
    const [dbVersions, setDbVersions] = useState({});
    const [mailTo, setMailTo] = useState('');
    const [yahooMeta, setYahooMeta] = useState('');
    const [playerYahooId, setPlayerYahooId] = useState('');
    const [allPlayers, setAllPlayers] = useState([]);
    const [playerInfo, setPlayerInfo] = useState('');
    const [currentSeason, setCurrentSeason] = useState({});
    const [currentLeague, setCurrentLeague] = useState({});
    const [currentAuction, setCurrentAuction] = useState({});
    const [selectedWeekNum, setSelectedWeekNum] = useState(null);
    const [consoleText, setConsoleText] = useState('');
    const [loading, setLoading] = useState(false);

    const isMobile = useMediaQuery('(max-width: 1510px)');

    const fetchDbHost = () => {
        apiGet(`admin/database`, (data) => {
            setDbMeta(data.data ? {host: data.data.host, name: data.data.name} : {});
        });
    };

    const fetchDbVersions = () => {
        apiGet(`admin/database/meta`, (data) => {
            setDbVersions(data.data ? {migration: data.data.migration.version, data: data.data.data.description} : {});
        });
    };

    const fetchEmailTo = () => {
        apiGet(`admin/mailto`, (data) => {
            setMailTo(data.data ? data.data.emailTo : '');
        });
    };

    const fetchCurrentSeason = () => {
        apiGet(`season/current`, (data) => {
            setCurrentSeason(data.data ? data.data : {});
        });
    };

    const fetchCurrentLeague = () => {
        apiGet(`league/current`, (data) => {
            setCurrentLeague(data.data ? data.data : {});
        });
    };

    const fetchCurrentAuction = () => {
        apiGet(`auction/current`, (data) => {
            if (data.data) {
                setCurrentAuction(data.data);
                setSelectedWeekNum(data.data.week_num - 1);
            }
        });
    };

    const fetchYahooMetadata = () => {
        setLoading(true);
        apiGet(`yahoo/meta`, (data) => {
            setYahooMeta(data.data ? data.data.fantasy_content.game[0].game_key : '');
            setLoading(false);
        });
    };

    const fetchAllPlayers = () => {
        apiGet(`player/all`, (data) => {
            setAllPlayers(data.data);
        });
    };

    const fetchPlayerInfo = () => {
        if (playerYahooId) {
            setLoading(true);
            apiGet(`player/yahoo/${playerYahooId}`, (data) => {
                if (data.data) {
                    setPlayerInfo(`${playerInfo}${playerYahooId}: ${data.data.first_name} ${data.data.last_name} (${data.data.pos} - ${data.data.team})\n`);
                }
                setLoading(false);
            });
        }
    };

    const fetchCompareRosters = () => {
        apiGet(`etl/compare/rosters`, (data) => {
            printMessage(`Compare roster cache files: ${JSON.stringify(data, null, 2)}`);
        });
    };

    const refresh = useCallback(() => {
        fetchDbHost();
        fetchDbVersions();
        fetchEmailTo();
        fetchCurrentSeason();
        fetchCurrentLeague();
        fetchCurrentAuction();
        fetchAllPlayers();
        setPlayerYahooId('');
        setPlayerInfo('');
        setConsoleText('');
    }, []);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const onPlayerYahooIdChange = (event) => {
        setPlayerYahooId(event.target.value);
    };

    const onPlayerChange = (event, value) => {
        if (value) {
            setPlayerInfo(`${playerInfo}${value.yahoo_id}: ${value.first_name} ${value.last_name} (${value.pos} - ${value.team})\n`);
        }
    };

    const setWeek = (week) => {
        setSelectedWeekNum(week);
    };

    const printMessage = (message) => {
        console.log(message);
        setConsoleText(`${consoleText}${"-".repeat(80)}\n${message}\n${"-".repeat(80)}\n`);
    };

    const submitFetchNFLScheduleData = () => {
        setLoading(true);
        const gamesPostBody = {
            year: currentSeason.year
        }
        apiPost(`admin/games/fetch/schedule`, gamesPostBody, (data) => {
            printMessage(`Populated game schedule data: ${JSON.stringify(data, null, 2)}`);
            setLoading(false);
        });
    };

    const submitFetchNFLResultsData = () => {
        setLoading(true);
        const gamesPostBody = {
            year: currentSeason.year,
            week: selectedWeekNum
        }
        apiPost(`admin/games/fetch/results`, gamesPostBody, (data) => {
            printMessage(`Populated game results data: ${JSON.stringify(data, null, 2)}`);
            setLoading(false);
        });
    };

    const submitYahooSyncPlayers = () => {
        setLoading(true);
        apiPost(`yahoo/sync/players`, {}, (data) => {
            printMessage(`Sync player data: ${JSON.stringify(data, null, 2)}`);
            setLoading(false);
        });
    };

    const submitYahooSyncTransactions = () => {
        setLoading(true);
        apiPost(`yahoo/sync/transactions`, {}, (data) => {
            printMessage(`Sync transaction data: ${JSON.stringify(data, null, 2)}`);
            setLoading(false);
        });
    };

    const submitYahooSyncPoints = () => {
        setLoading(true);
        const postBody = {
            year: currentSeason.year
        }
        apiPost(`yahoo/sync/points`, postBody, (data) => {
            printMessage(`Sync points data: ${JSON.stringify(data, null, 2)}`);
            setLoading(false);
        });
    };

    const submitYahooSyncMatchups = () => {
        setLoading(true);
        const postBody = {
            year: currentSeason.year
        }
        apiPost(`yahoo/sync/matchups`, postBody, (data) => {
            printMessage(`Sync matchups data: ${JSON.stringify(data, null, 2)}`);
            setLoading(false);
        });
    };

    const submitMatchTransactions = () => {
        setLoading(true);
        apiPost(`contract/match`, {}, (data) => {
            printMessage(`Match contracts and transactions: ${JSON.stringify(data, null, 2)}`);
            setLoading(false);
        });
    };

    const submitRefreshMaterializedViews = () => {
        setLoading(true);
        apiPost(`roster/view/refresh`, {}, (data) => {
            apiPost(`scoreboard/view/refresh`, {}, (data) => {
                apiPost(`stats/view/refresh`, {}, (data) => {
                    printMessage(`Refresh rosters MV: ${JSON.stringify(data, null, 2)}\nRefresh scoreboard MV: ${JSON.stringify(data, null, 2)}\nRefresh active contract stats MV: ${JSON.stringify(data, null, 2)}`);
                    setLoading(false);
                });
            });
        });
    };

    const submitUpdateCacheFilesFFED = () => {
        setLoading(true);
        apiPost(`etl/cache/ffed/rosters/all`, {}, (data) => {
            printMessage(`Update FFED rosters cache: ${JSON.stringify(data, null, 2)}`);
            setLoading(false);
        });
    };

    const submitUpdateCacheFilesYahoo = () => {
        setLoading(true);
        const updatePostBody = {
            year: currentSeason.year
        }
        apiPost(`etl/cache/yahoo/rosters/year`, updatePostBody, (dataRosters) => {
            apiPost(`etl/cache/yahoo/points/year`, updatePostBody, (dataPoints) => {
                apiPost(`etl/cache/yahoo/matchups/year`, updatePostBody, (dataMatchups) => {
                    printMessage(`Update Yahoo rosters cache: ${JSON.stringify(dataRosters, null, 2)}\nUpdate Yahoo points: ${JSON.stringify(dataPoints, null, 2)}\nUpdate Yahoo matchups: ${JSON.stringify(dataMatchups, null, 2)}`);
                    setLoading(false);
                });
            });
        });
    };

    const submitAdvanceToPreDraft = () => {
        setLoading(true);
        apiPost(`admin/season/advance/predraft`, {}, (data) => {
            printMessage(`Advanced to pre-draft of season ${data.data.advance_to_predraft}`);
            setLoading(false);
        });
    };

    const submitAdvanceSeason = () => {
        setLoading(true);
        apiPost(`admin/season/advance`, {}, (data) => {
            printMessage(`Advanced contracts to ${data.data.advance_contracts}`);
            setLoading(false);
        });
    };

    const submitAdvanceToDraft = () => {
        setLoading(true);
        apiPost(`admin/season/advance/draft`, {}, (data) => {
            printMessage(`Advanced to draft of season ${data.data.advance_to_draft}`);
            setLoading(false);
        });
    };

    const justify = isMobile ? 'flex-start' : 'center';
    const gridCellStyle = {backgroundColor: "#F2F2F2", border: "10px white solid"}; //TODO: use class from index.css...
    return (
        <Grid container justifyContent={justify}>
            <Grid container justifyContent={'center'} spacing={1} style={{maxWidth: 1500, minWidth: 1500, marginTop: 30}}>
                <Grid item xs={12}>
                    <div style={{float: "right"}}>
                        <IconButton color="inherit" style={{height: 25}} onClick={refresh}>
                            <RotateLeft/>
                        </IconButton>
                    </div>
                    <br/>
                    {loading && <LinearProgress color="secondary"/>}
                </Grid>
                <Grid item xs={6} style={gridCellStyle}>
                    <Typography variant="h6" color="inherit" display="inline" style={{marginLeft: 5}}>
                        Metadata:
                    </Typography>
                    <br/>
                    <pre>  Database host: {dbMeta.host}</pre>
                    <pre>  Database name: {dbMeta.name}</pre>
                    <pre>  Database migration: {dbVersions.migration}</pre>
                    <pre>  Database data version: {dbVersions.data}</pre>
                    <pre>  Email to: {mailTo}</pre>
                </Grid>
                <Grid item xs={3} style={gridCellStyle}>
                    <Typography variant="h6" color="inherit" display="inline" style={{marginLeft: 5}}>
                        Status:
                    </Typography>
                    <br/>
                    <pre>  Yahoo game key: {yahooMeta ? yahooMeta :
                        <Button variant="contained" type="submit" color="primary" style={{height: 18}}
                                onClick={fetchYahooMetadata}>Fetch</Button>}</pre>
                    <pre>  Current season year: {currentSeason.year}</pre>
                    <pre>  Current season phase: {currentLeague.season_phase}</pre>
                    <pre>  Current auction: {currentAuction.season_year} week {currentAuction.week_num}</pre>
                </Grid>
                <Grid item xs={3} style={gridCellStyle}>
                    <Typography variant="h6" color="inherit" display="inline" style={{marginLeft: 5}}>
                        Player Search:
                    </Typography>
                    <br/>
                    <pre>  <TextField variant="outlined" size="small" label="Yahoo ID" value={playerYahooId}
                                      onKeyDown={e => {if (e.key === 'Enter') fetchPlayerInfo()}}
                                      onChange={e => onPlayerYahooIdChange(e)}/>
                    <Button variant="contained" type="submit" color="primary" style={{marginLeft: 10}}
                            onClick={fetchPlayerInfo}>Search</Button></pre>
                    <Autocomplete id="playerSelectionField" freeSolo style={{width: 300, marginLeft: 16}}
                                  options={allPlayers} onChange={onPlayerChange}
                                  getOptionLabel={(p) => `${p.first_name} ${p.last_name} (${p.pos} - ${p.team})`}
                                  renderInput={(params) => (
                                      <TextField {...params} label="Search" margin="none" variant="outlined"/>
                                  )}/>
                    <div style={{marginLeft: 20}}>
                        <pre>{playerInfo}</pre>
                    </div>
                </Grid>
                <Grid item xs={4} style={gridCellStyle}>
                    <Typography variant="h6" color="inherit" display="inline" style={{marginLeft: 5}}>
                        Seasonal Actions:
                    </Typography>
                    <br/>
                    <Button variant="contained" type="submit" color="primary" style={{margin: 15}}
                            onClick={submitAdvanceToPreDraft}>Advance to {currentSeason.year} pre-draft</Button>
                    <br/>
                    <Button variant="contained" type="submit" color="primary" style={{margin: 15}}
                            onClick={submitAdvanceSeason}>Advance contracts to {currentSeason.year}</Button>
                    <br/>
                    <Button variant="contained" type="submit" color="primary" style={{margin: 15}}
                            onClick={submitAdvanceToDraft}>Advance to {currentSeason.year} draft</Button>
                    <br/>
                    <Button variant="contained" type="submit" color="primary" style={{margin: 15}}
                            onClick={submitFetchNFLScheduleData}>Populate NFL schedule data</Button>
                </Grid>
                <Grid item xs={4} style={gridCellStyle}>
                    <Typography variant="h6" color="inherit" display="inline" style={{marginLeft: 5}}>
                        Weekly Fetches:
                    </Typography>
                    <br/>
                    <Button variant="contained" type="submit" color="primary" style={{margin: 15}}
                            onClick={submitYahooSyncTransactions}>1) Sync transaction data</Button>
                    <br/>
                    <Button variant="contained" type="submit" color="primary" style={{margin: 15}}
                            onClick={submitMatchTransactions}>2) Match contracts and transactions</Button>
                    <br/>
                    <Button variant="contained" type="submit" color="primary" style={{margin: 15}}
                            onClick={submitYahooSyncPlayers}>3) Sync player data</Button>
                    <br/>
                    <div>
                        <Button variant="contained" type="submit" color="primary" style={{margin: 15}}
                                onClick={submitFetchNFLResultsData}>4) Populate NFL results data</Button>
                        <div style={{float: "right", paddingTop: 5, paddingRight: 30}}>
                        <WeekPicker setterFunction={setWeek} currentAuction={currentAuction} includeDraft={false}
                                    includeCurrent={false} weekOnlyMode={true}/>
                        </div>
                    </div>
                    <Button variant="contained" type="submit" color="primary" style={{margin: 15}}
                            onClick={submitUpdateCacheFilesYahoo}>5) Update Yahoo cache files</Button>
                </Grid>
                <Grid item xs={4} style={gridCellStyle}>
                    <Typography variant="h6" color="inherit" display="inline" style={{marginLeft: 5}}>
                        Weekly DB Updates:
                    </Typography>
                    <br/>
                    <Button variant="contained" type="submit" color="primary" style={{margin: 15}}
                            onClick={submitYahooSyncPoints}>6) Sync points from cache into DB</Button>
                    <br/>
                    <Button variant="contained" type="submit" color="primary" style={{margin: 15}}
                            onClick={submitYahooSyncMatchups}>7) Sync matchups from cache into DB</Button>
                    <br/>
                    <Button variant="contained" type="submit" color="primary" style={{margin: 15}}
                            onClick={submitRefreshMaterializedViews}>8) Refresh materialized views</Button>
                    <br/>
                    <Button variant="contained" type="submit" color="primary" style={{margin: 15}}
                            onClick={submitUpdateCacheFilesFFED}>9) Update FFED rosters cache</Button>
                    <br/>
                    <Button variant="contained" type="submit" color="primary" style={{margin: 15}}
                            onClick={fetchCompareRosters}>10) Compare roster cache files</Button>
                </Grid>
                <Grid item xs={12} style={{marginLeft: 10}}>
                    <Typography variant="h6" color="inherit" display="inline">
                        Console:
                    </Typography>
                    <br/>
                    <pre>{consoleText}</pre>
                    <br/>
                    <br/>
                </Grid>
            </Grid>
        </Grid>
    );
}