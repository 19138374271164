import React, {useState} from 'react';
import {NavLink, useHistory} from 'react-router-dom';
import Drawer from "@material-ui/core/Drawer";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {NavigationTabs} from "../components/NavigationTabs";
import {ProfileMenu} from "../components/ProfileMenu";
import {ListItemButton, useMediaQuery} from "@mui/material";
import {Box, Divider, List, ListItem, ListItemText} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

const defaultTabs = [
    // {label: 'Home', to: '/home'},
    {label: 'Rosters', to: '/rosters'},
    {label: 'Auction', to: '/auction'},
    {label: 'Trade', to: '/trade'},
    // {label: 'Pre-Draft', to: '/predraft'},
    // {label: 'Draft', to: '/draft'},
    {label: 'Stats', to: '/stats'},
    {label: 'History', to: '/history'},
    {label: 'Records', to: '/records'},
    {label: 'Activity', to: '/activity'},
    {label: 'Wins', to: '/wins'}
];

// const adminTabs = [...defaultTabs, {label: 'Draft Trade', to: '/drafttrade'}, {label: 'Admin', to: '/admin'}];
const adminTabs = [...defaultTabs, {label: 'Admin', to: '/admin'}];

export const NavigationContainer = ({managerName, setManagerName, isAdmin, setIsAdmin}) => {
    let tabs = isAdmin ? adminTabs : defaultTabs;
    const indexOfTab = (to) => {
        return tabs.map((t) => {
            return t.to
        }).indexOf(to);
    };
    let history = useHistory();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [currentTab, setCurrentTab] = useState(indexOfTab(history.location.pathname));
    const [profileMenuOpen, setProfileMenuOpen] = useState(false);
    const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState(null);

    const isMobile = useMediaQuery('(max-width: 1550px)');

    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    const handleChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const handleOpenMenu = (event) => {
        setProfileMenuAnchorEl(event.target);
        setProfileMenuOpen(true);
    };

    const handleCloseMenu = () => {
        setProfileMenuOpen(false);
    };

    const onClickLogout = () => {
        setProfileMenuOpen(false);
        history.push('/');
        setManagerName(null);
        setIsAdmin(false);
    };

    const drawerList = (
        <Box sx={{width: 150}} role="presentation" onClick={toggleDrawer(false)}>
            <List>
                <ListItem key="ffed">
                    <Typography variant="h6" color="inherit">FFED</Typography>
                </ListItem>
            </List>
            <Divider/>
            <List>
                {tabs.map((tab) => (
                    <ListItem key={tab.label} component={NavLink} to={tab.to}>
                        <ListItemButton>
                            <ListItemText primary={tab.label}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider/>
            <List>
                <ListItem key="logout" onClick={onClickLogout}>
                    <ListItemButton>
                        <ListItemText primary="Log out"/>
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    return (
        <div style={{position: 'sticky', top: 0, left: 0, width: '100%'}}>
            {isMobile &&
                <AppBar style={{height: 48}}>
                    {managerName &&
                        <Toolbar variant="dense">
                            <IconButton edge="end" color="inherit" onClick={toggleDrawer(true)}>
                                <MenuIcon/>
                                <Typography variant="h6" color="inherit" style={{marginLeft: 10}}>FFED</Typography>
                            </IconButton>
                            <Drawer open={drawerOpen} onClose={toggleDrawer(false)}>{drawerList}</Drawer>
                            <div style={{flexGrow: 1}}/>
                            <Typography variant="subtitle1" color="inherit" display="inline">{managerName}</Typography>
                        </Toolbar>
                    }
                    {!managerName &&
                        <Toolbar variant="dense">
                            <Typography variant="h6" color="inherit" style={{marginLeft: 10}}>FFED</Typography>
                        </Toolbar>
                    }
                </AppBar>
            }
            {!isMobile &&
                <AppBar style={{height: 48}}>
                    <Toolbar variant="dense">
                        <Typography variant="h6" color="inherit">FFED</Typography>
                        <div style={{flexGrow: 1}}/>
                        {managerName &&
                            <NavigationTabs tabs={tabs} currentTab={currentTab} handleChange={handleChange}/>
                        }
                        <div style={{flexGrow: 1}}/>
                        {managerName &&
                            <ProfileMenu managerName={managerName} isAdmin={isAdmin} anchorEl={profileMenuAnchorEl}
                                         profileMenuOpen={profileMenuOpen} handleOpenMenu={handleOpenMenu}
                                         handleCloseMenu={handleCloseMenu} onClickLogout={onClickLogout}/>
                        }
                    </Toolbar>
                </AppBar>
            }
        </div>
    );
}