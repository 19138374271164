import React from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import {Album} from "@material-ui/icons";
import {Star} from "@material-ui/icons";
import {getUserName} from "../utils";

//TODO: move to classes in index.css...
const headerCellStyle = {border: 'thin solid black', padding: 5};
const rowCellStyle = {border: 'thin solid black', padding: 10};

const medals = {gold: 'gold', silver: 'silver', bronze: '#CD7F32'};

const renderCell = (yearsString, medal, star) => {
    const years = yearsString ? yearsString.split(', ') : [];
    const result = [];
    for (const year of years) {
        if (medal) result.push(<Album style={{color: medals[medal], marginRight: 3, verticalAlign: "middle"}}/>);
        if (star) result.push(<Star style={{color: "lightblue", marginRight: 3, verticalAlign: "middle"}}/>);
        result.push(year);
        result.push(<br/>);
    }
    return result;
};

export const TrophiesTable = ({rows, loading}) => {
    if (rows.length > 0) {
        return (
            <TableContainer style={{width: 900}}>
                <Table>
                    <TableHead>
                        <TableRow key="trophiesH1" className="grid-header">
                            <TableCell style={{...headerCellStyle, width: '12%', paddingLeft: 15}} rowSpan={2}>Manager</TableCell>
                            <TableCell style={headerCellStyle} align="center" colSpan={4}>Final</TableCell>
                            <TableCell style={headerCellStyle} align="center" colSpan={4}>Regular Season</TableCell>
                        </TableRow>
                        <TableRow key="trophiesH2" className="grid-header">
                            <TableCell style={{...headerCellStyle, width: '11%'}} align="center">Winner</TableCell>
                            <TableCell style={{...headerCellStyle, width: '11%'}} align="center">2nd</TableCell>
                            <TableCell style={{...headerCellStyle, width: '11%'}} align="center">3rd</TableCell>
                            <TableCell style={{...headerCellStyle, width: '11%'}} align="center">Points</TableCell>
                            <TableCell style={{...headerCellStyle, width: '11%'}} align="center">1st</TableCell>
                            <TableCell style={{...headerCellStyle, width: '11%'}} align="center">2nd</TableCell>
                            <TableCell style={{...headerCellStyle, width: '11%'}} align="center">3rd</TableCell>
                            <TableCell style={{...headerCellStyle, width: '11%'}} align="center">Points</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => {
                            return <TableRow key={`trophies${row.key}`} className={row.manager_name === getUserName()? 'is-self' : ''}>
                                <TableCell key={`trophies${row.key}1`} style={{...rowCellStyle, paddingLeft: 15}}>{row.manager_name}</TableCell>
                                <TableCell key={`trophies${row.key}2`} style={rowCellStyle} align="center">{renderCell(row.final_first_years, 'gold')}</TableCell>
                                <TableCell key={`trophies${row.key}3`} style={rowCellStyle} align="center">{renderCell(row.final_second_years, 'silver')}</TableCell>
                                <TableCell key={`trophies${row.key}4`} style={rowCellStyle} align="center">{renderCell(row.final_third_years, 'bronze')}</TableCell>
                                <TableCell key={`trophies${row.key}5`} style={rowCellStyle} align="center">{renderCell(row.full_season_points_win_years, null, true)}</TableCell>
                                <TableCell key={`trophies${row.key}6`} style={rowCellStyle} align="center">{renderCell(row.reg_season_first_years)}</TableCell>
                                <TableCell key={`trophies${row.key}7`} style={rowCellStyle} align="center">{renderCell(row.reg_season_second_years)}</TableCell>
                                <TableCell key={`trophies${row.key}8`} style={rowCellStyle} align="center">{renderCell(row.reg_season_third_years)}</TableCell>
                                <TableCell key={`trophies${row.key}9`} style={rowCellStyle} align="center">{renderCell(row.reg_season_points_win_years)}</TableCell>
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    } else {
        return (
            <div>
                {loading && <LinearProgress color="secondary"/>}
                <Typography variant="body1" color="inherit" style={{marginLeft: 15, paddingTop: 20}}>
                    Loading trophies table...
                </Typography>
            </div>
        )
    }
}