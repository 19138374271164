import React, {useEffect, useState} from "react";
import {apiGet} from "../utils";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import {WinsPoolResultsGrid} from "../components/WinsPoolResultsGrid";
import Button from "@material-ui/core/Button";
import {WinsPoolChart} from "../components/WinsPoolChart";
import {useMediaQuery} from "@mui/material";
import {SeasonPicker} from "../components/SeasonPicker";

export const WinsPoolContainer = () => {
    const [seasonYears, setSeasonYears] = useState([]);
    const [currentAuction, setCurrentAuction] = useState({});
    const [selectedSeasonYear, setSelectedSeasonYear] = useState(null);
    const [picks, setPicks] = useState([]);
    const [gameResults, setGameResults] = useState({});
    const [showChart, setShowChart] = useState(false);
    const [loading, setLoading] = useState(true);

    const isMobile = useMediaQuery('(max-width: 1275px)');

    const fetchWinsSeasonYears = () => {
        setLoading(true);
        apiGet(`wins/seasons`, (data) => {
            setSeasonYears(data.data ? data.data.map(s => s.year) : []);
            setLoading(false);
        });
    };

    const fetchWinsPoolPicks = (season = null) => {
        setLoading(true);
        const urlSeasonParam = (season != null) ? `/season/${season}` : '';
        apiGet(`wins/picks${urlSeasonParam}`, (data) => {
            setPicks(data.data ? data.data : []);
            setLoading(false);
        });
    };

    const fetchGameResults = (season = null) => {
        setLoading(true);
        const urlSeasonParam = (season != null) ? `/season/${season}` : '';
        apiGet(`wins/results${urlSeasonParam}`, (data) => {
            setGameResults(data.data ? data.data : {});
            setLoading(false);
        });
    };

    const setYear = (year) => {
        setSelectedSeasonYear(year);
    };

    useEffect(() => {
        fetchWinsSeasonYears();
        fetchWinsPoolPicks();
        fetchGameResults();
        apiGet(`auction/current`, (data) => {
            setCurrentAuction(data.data);
            setYear(data.data.season_year);
        });
    }, []);

    useEffect(() => {
        fetchWinsPoolPicks(selectedSeasonYear);
        fetchGameResults(selectedSeasonYear);
    }, [selectedSeasonYear]);

    const handleClickShowChart = () => {
        setShowChart(true);
    };

    const justify = isMobile ? 'flex-start' : 'center';
    return (
        <Grid container justifyContent={justify}>
            <table>
                <tbody>
                <tr>
                    <td>
                        <div style={{textAlign: "center"}}>
                            <div style={{textAlign: "right", marginBottom: 15}}>
                                <SeasonPicker setterFunction={setYear} currentAuction={currentAuction} seasonYearsOverride={seasonYears}/>
                            </div>
                            {loading && <LinearProgress color="secondary"/>}
                            <WinsPoolResultsGrid picks={picks} gameResults={gameResults}/>
                            <br/>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div style={{textAlign: "center"}}>
                            {!showChart &&
                                <Button
                                    style={{backgroundColor: "lightgray", height: 25, marginLeft: 10, marginRight: 10}}
                                    onClick={handleClickShowChart}>Show Chart</Button>
                            }
                            {showChart &&
                                <div style={{marginBottom: 50, marginLeft: 10, marginRight: 10}}>
                                    <WinsPoolChart picks={picks} gameResults={gameResults}/>
                                </div>
                            }
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </Grid>
    );
}