import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {useMediaQuery} from "@mui/material";
import {apiGet, recordListGridTypes} from "../utils";
import {Box, Tab, Tabs, Typography} from "@material-ui/core";
import {TabContext, TabPanel} from "@material-ui/lab";
import {SeasonResultsGrid} from "../components/SeasonResultsGrid";
import {SeasonPicker} from "../components/SeasonPicker";
import {WeekPicker} from "../components/WeekPicker";
import {MatchupsGrid} from "../components/MatchupsGrid";
import {TrophiesTable} from "../components/TrophiesTable";
import {RecordListGrid} from "../components/RecordListGrid";
import {DirtyCleanSummaryGrid} from "../components/DirtyCleanSummaryGrid";

export const RecordsContainer = () => {
    const [currentAuction, setCurrentAuction] = useState({});
    const [selectedTab, setSelectedTab] = useState('1');
    const [matchupsYear, setMatchupsYear] = useState(null);
    const [matchupsWeek, setMatchupsWeek] = useState(null);
    const [seasonResultsYear, setSeasonResultsYear] = useState(null);
    const [matchupsRows, setMatchupsRows] = useState([]);
    const [seasonResultsRows, setSeasonResultsRows] = useState([]);
    const [allTimeResultsRows, setAllTimeResultsRows] = useState([]);
    const [trophiesRows, setTrophiesRows] = useState([]);
    const [dirtyCleanSummaryRows, setDirtyCleanSummaryRows] = useState([]);
    const [matchupsLoading, setMatchupsLoading] = useState(false);
    const [seasonResultsLoading, setSeasonResultsLoading] = useState(false);
    const [allTimeResultsLoading, setAllTimeResultsLoading] = useState(false);
    const [trophiesLoading, setTrophiesLoading] = useState(false);
    const [dirtyCleanSummaryLoading, setDirtyCleanSummaryLoading] = useState(false);

    const isMobile = useMediaQuery('(max-width: 1665px)');

    const fetchMatchupsData = (seasonYear, weekNum) => {
        if (seasonYear) {
            setMatchupsLoading(true);
            apiGet(`scoreboard/season/${seasonYear}/week/${weekNum}`, (data) => {
                if (data.data) {
                    const collapsedMatchupRows = [];
                    const allScores = [];
                    for (const matchup of data.data.filter(m => m.win_loss === 'W')) {
                        allScores.push(parseFloat(matchup.total_points));
                        allScores.push(parseFloat(matchup.opp_total_points));
                        collapsedMatchupRows.push({
                            id: matchup.id,
                            winningManager: matchup.manager_name,
                            winnerPoints: parseFloat(matchup.total_points),
                            losingManager: matchup.opponent_name,
                            loserPoints: parseFloat(matchup.opp_total_points)
                        })
                    }
                    allScores.sort((a, b) => a - b);
                    for (const matchupRow of collapsedMatchupRows) {
                        if (allScores.indexOf(matchupRow.winnerPoints) === 1 && allScores.indexOf(matchupRow.loserPoints) === 0) {
                            matchupRow.isDirtyReitz = true;
                        }
                    }
                    allScores.sort((a, b) => b - a);
                    for (const matchupRow of collapsedMatchupRows) {
                        if (allScores.indexOf(matchupRow.winnerPoints) === 0 && allScores.indexOf(matchupRow.loserPoints) === 1) {
                            matchupRow.isCleanRandall = true;
                        }
                    }
                    collapsedMatchupRows.sort((a, b) => {
                        return a.winnerPoints > b.winnerPoints ? -1 : 1;
                    });
                    setMatchupsRows(collapsedMatchupRows);
                    setMatchupsLoading(false);
                }
            });
        }
    };

    const fetchSeasonResultsData = (seasonYear) => {
        if (seasonYear) {
            setSeasonResultsLoading(true);
            apiGet(`result/season/${seasonYear}`, (data) => {
                setSeasonResultsRows(data.data);
                setSeasonResultsLoading(false);
            });
        }
    };

    const fetchAllTimeResultsData = () => {
        setAllTimeResultsLoading(true);
        apiGet(`result/all`, (data) => {
            setAllTimeResultsRows(data.data);
            setAllTimeResultsLoading(false);
        });
    };

    const fetchTrophiesData = () => {
        setTrophiesLoading(true);
        apiGet(`record/trophies`, (data) => {
            setTrophiesRows(data.data);
            setTrophiesLoading(false);
        });
    };

    const fetchDirtyCleanSummaryData = () => {
        setDirtyCleanSummaryLoading(true);
        apiGet(`record/dirtyclean/summary/all`, (data) => {
            setDirtyCleanSummaryRows(data.data);
            setDirtyCleanSummaryLoading(false);
        });
    };

    const setSelectedMatchupsYearAndWeek = (year, week) => {
        setMatchupsYear(year);
        setMatchupsWeek(week);
    };

    const setSelectedSeasonResultsYear = (year) => {
        setSeasonResultsYear(year);
    };

    useEffect(() => {
        apiGet(`auction/current`, (data) => {
            setCurrentAuction(data.data);
            setSelectedMatchupsYearAndWeek(data.data.season_year, data.data.week_num - 1);
            setSelectedSeasonResultsYear(data.data.season_year);
            fetchMatchupsData(data.data.season_year, data.data.week_num - 1);
            fetchSeasonResultsData(data.data.season_year);
            fetchAllTimeResultsData();
            fetchTrophiesData();
            fetchDirtyCleanSummaryData();
        });
    }, []);

    useEffect(() => {
        fetchMatchupsData(matchupsYear, matchupsWeek);
    }, [matchupsYear, matchupsWeek]);

    useEffect(() => {
        fetchSeasonResultsData(seasonResultsYear)
    }, [seasonResultsYear]);

    const handleTabChange = (event, newTabIndex) => {
        setSelectedTab(newTabIndex);
    };

    const components = {
        mainByWeek: {
            label: <Typography variant="h6" style={{marginLeft: 10}}>By Week</Typography>,
            picker: <WeekPicker setterFunction={setSelectedMatchupsYearAndWeek} currentAuction={currentAuction} includeDraft={false} includeWeek17={false} includeCurrent={false}/>,
            grid: <MatchupsGrid rows={matchupsRows} loading={matchupsLoading}/>
        },
        mainBySeason: {
            label: <Typography variant="h6" style={{marginLeft: 10}}>By Season</Typography>,
            picker: <SeasonPicker setterFunction={setSelectedSeasonResultsYear} currentAuction={currentAuction}/>,
            grid: <SeasonResultsGrid rows={seasonResultsRows} loading={seasonResultsLoading}/>
        },
        mainAllTime: {
            label: <Typography variant="h6" style={{margin: 10}}>All Time</Typography>,
            grid: <SeasonResultsGrid rows={allTimeResultsRows} loading={allTimeResultsLoading} isAllTimeSummary={true}/>
        },
        trophies: {
            table: <TrophiesTable rows={trophiesRows} loading={trophiesLoading}/>
        },
        managerPointsWeek: {
            top: {
                label: <Typography variant="h6" style={{margin: 10}}>Most Points By Manager In Week</Typography>,
                grid: <RecordListGrid type={recordListGridTypes.WITH_WEEK} managerPlayerMatchup="manager" pointsOrAgainst="points" weekRegularFull="week" topOrBottom="top"/>
            },
            bottom: {
                label: <Typography variant="h6" style={{margin: 10}}>Fewest Points By Manager In Week</Typography>,
                grid: <RecordListGrid type={recordListGridTypes.WITH_WEEK} managerPlayerMatchup="manager" pointsOrAgainst="points" weekRegularFull="week" topOrBottom="bottom"/>
            }
        },
        managerPointsRegular: {
            top: {
                label: <Typography variant="h6" style={{margin: 10}}>Most Points By Manager In Regular Season</Typography>,
                grid: <RecordListGrid type={recordListGridTypes.STANDARD} managerPlayerMatchup="manager" pointsOrAgainst="points" weekRegularFull="regular" topOrBottom="top"/>
            },
            bottom: {
                label: <Typography variant="h6" style={{margin: 10}}>Fewest Points By Manager In Regular Season</Typography>,
                grid: <RecordListGrid type={recordListGridTypes.STANDARD} managerPlayerMatchup="manager" pointsOrAgainst="points" weekRegularFull="regular" topOrBottom="bottom"/>
            }
        },
        managerPointsFull: {
            top: {
                label: <Typography variant="h6" style={{margin: 10}}>Most Points By Manager In Full Season</Typography>,
                grid: <RecordListGrid type={recordListGridTypes.STANDARD} managerPlayerMatchup="manager" pointsOrAgainst="points" weekRegularFull="full" topOrBottom="top"/>
            },
            bottom: {
                label: <Typography variant="h6" style={{margin: 10}}>Fewest Points By Manager In Full Season</Typography>,
                grid: <RecordListGrid type={recordListGridTypes.STANDARD} managerPlayerMatchup="manager" pointsOrAgainst="points" weekRegularFull="full" topOrBottom="bottom"/>
            }
        },
        managerAgainstRegular: {
            top: {
                label: <Typography variant="h6" style={{margin: 10}}>Most Points Against Manager In Regular Season</Typography>,
                grid: <RecordListGrid type={recordListGridTypes.STANDARD} managerPlayerMatchup="manager" pointsOrAgainst="against" weekRegularFull="regular" topOrBottom="top"/>
            },
            bottom: {
                label: <Typography variant="h6" style={{margin: 10}}>Fewest Points Against Manager In Regular Season</Typography>,
                grid: <RecordListGrid type={recordListGridTypes.STANDARD} managerPlayerMatchup="manager" pointsOrAgainst="against" weekRegularFull="regular" topOrBottom="bottom"/>
            }
        },
        managerAgainstFull: {
            top: {
                label: <Typography variant="h6" style={{margin: 10}}>Most Points Against Manager In Full Season</Typography>,
                grid: <RecordListGrid type={recordListGridTypes.STANDARD} managerPlayerMatchup="manager" pointsOrAgainst="against" weekRegularFull="full" topOrBottom="top"/>
            },
            bottom: {
                label: <Typography variant="h6" style={{margin: 10}}>Fewest Points Against Manager In Full Season</Typography>,
                grid: <RecordListGrid type={recordListGridTypes.STANDARD} managerPlayerMatchup="manager" pointsOrAgainst="against" weekRegularFull="full" topOrBottom="bottom"/>
            }
        },
        playerPointsWeek: {
            top: {
                label: <Typography variant="h6" style={{margin: 10}}>Most Points By Player In Week</Typography>,
                grid: <RecordListGrid type={recordListGridTypes.WITH_WEEK_AND_PLAYER} managerPlayerMatchup="player" pointsOrAgainst="points" weekRegularFull="week" topOrBottom="top"/>
            },
            bottom: {
                label: <Typography variant="h6" style={{margin: 10}}>Fewest Points By Player In Week</Typography>,
                grid: <RecordListGrid type={recordListGridTypes.WITH_WEEK_AND_PLAYER} managerPlayerMatchup="player" pointsOrAgainst="points" weekRegularFull="week" topOrBottom="bottom"/>
            }
        },
        matchupPointsDiff: {
            top: {
                label: <Typography variant="h6" style={{margin: 10}}>Biggest Points Difference In Matchup</Typography>,
                grid: <RecordListGrid type={recordListGridTypes.MATCHUP_DIFF} managerPlayerMatchup="matchup" topOrBottom="top"/>
            },
            bottom: {
                label: <Typography variant="h6" style={{margin: 10}}>Smallest Points Difference In Matchup</Typography>,
                grid: <RecordListGrid type={recordListGridTypes.MATCHUP_DIFF} managerPlayerMatchup="matchup" topOrBottom="bottom"/>
            }
        },
        filthSummary: {
            label: <Typography variant="h6" style={{margin: 10}}>Filth Summary</Typography>,
            grid: <DirtyCleanSummaryGrid rows={dirtyCleanSummaryRows} loading={dirtyCleanSummaryLoading}/>
        },
        dirtyClean: {
            dirty: {
                label: <Typography variant="h6" style={{margin: 10}}>All Dirty Reitz Matchups</Typography>,
                grid: <RecordListGrid type={recordListGridTypes.DIRTY_CLEAN} managerPlayerMatchup="matchup" dirtyOrClean="dirty"/>
            },
            clean: {
                label: <Typography variant="h6" style={{margin: 10}}>All Clean Randall Matchups</Typography>,
                grid: <RecordListGrid type={recordListGridTypes.DIRTY_CLEAN} managerPlayerMatchup="matchup" dirtyOrClean="clean"/>
            }
        }
    };

    const mobileViewTableRows = {
        tabManager: [
            <tr><td>{components.managerPointsWeek.top.label}</td></tr>,
            <tr><td>{components.managerPointsWeek.top.grid}</td></tr>,
            <tr><td>{components.managerPointsWeek.bottom.label}</td></tr>,
            <tr><td>{components.managerPointsWeek.bottom.grid}</td></tr>,
            <tr><td>{components.managerPointsRegular.top.label}</td></tr>,
            <tr><td>{components.managerPointsRegular.top.grid}</td></tr>,
            <tr><td>{components.managerPointsRegular.bottom.label}</td></tr>,
            <tr><td>{components.managerPointsRegular.bottom.grid}</td></tr>,
            <tr><td>{components.managerPointsFull.top.label}</td></tr>,
            <tr><td>{components.managerPointsFull.top.grid}</td></tr>,
            <tr><td>{components.managerPointsFull.bottom.label}</td></tr>,
            <tr><td>{components.managerPointsFull.bottom.grid}</td></tr>,
            <tr><td>{components.managerAgainstRegular.top.label}</td></tr>,
            <tr><td>{components.managerAgainstRegular.top.grid}</td></tr>,
            <tr><td>{components.managerAgainstRegular.bottom.label}</td></tr>,
            <tr><td>{components.managerAgainstRegular.bottom.grid}</td></tr>,
            <tr><td>{components.managerAgainstFull.top.label}</td></tr>,
            <tr><td>{components.managerAgainstFull.top.grid}</td></tr>,
            <tr><td>{components.managerAgainstFull.bottom.label}</td></tr>,
            <tr><td>{components.managerAgainstFull.bottom.grid}</td></tr>
        ],
        tabPlayer: [
            <tr><td>{components.playerPointsWeek.top.label}</td></tr>,
            <tr><td>{components.playerPointsWeek.top.grid}</td></tr>,
            <tr><td>{components.playerPointsWeek.bottom.label}</td></tr>,
            <tr><td>{components.playerPointsWeek.bottom.grid}</td></tr>
        ],
        tabMatchup: [
            <tr><td>{components.matchupPointsDiff.top.label}</td></tr>,
            <tr><td>{components.matchupPointsDiff.top.grid}</td></tr>,
            <tr><td>{components.matchupPointsDiff.bottom.label}</td></tr>,
            <tr><td>{components.matchupPointsDiff.bottom.grid}</td></tr>,
            <tr><td>{components.filthSummary.label}</td></tr>,
            <tr><td>{components.filthSummary.grid}</td></tr>,
            <tr><td>{components.dirtyClean.dirty.label}</td></tr>,
            <tr><td>{components.dirtyClean.dirty.grid}</td></tr>,
            <tr><td>{components.dirtyClean.clean.label}</td></tr>,
            <tr><td>{components.dirtyClean.clean.grid}</td></tr>
        ]
    };

    const justify = isMobile ? 'flex-start' : 'center';
    return (
        <div>
            <Grid container justifyContent={justify}>
                <TabContext value={selectedTab}>
                    <div>
                        <Grid container justifyContent={justify}>
                            <div>
                                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                    <Tabs value={selectedTab} onChange={handleTabChange}>
                                        <Tab value="1" label="Main"/>
                                        <Tab value="2" label="Trophies"/>
                                        <Tab value="3" label="Manager"/>
                                        <Tab value="4" label="Player"/>
                                        <Tab value="5" label="Matchup"/>
                                    </Tabs>
                                </Box>
                            </div>
                        </Grid>
                        <div>
                            <TabPanel value="1">
                                <Grid container justifyContent={justify} style={{maxWidth: 1665}}>
                                    <Grid item xs={12}>{components.mainByWeek.label}</Grid>
                                    <Grid item xs={12}><div>{components.mainByWeek.picker}</div></Grid>
                                    <Grid item xs={12}>{components.mainByWeek.grid}</Grid>
                                    <Grid item xs={12}><br/><br/><br/>{components.mainBySeason.label}</Grid>
                                    <Grid item xs={12}><div>{components.mainBySeason.picker}</div></Grid>
                                    <Grid item xs={12}>{components.mainBySeason.grid}</Grid>
                                    <Grid item xs={12}><br/><br/><br/>{components.mainAllTime.label}</Grid>
                                    <Grid item xs={12}>{components.mainAllTime.grid}</Grid>
                                </Grid>
                            </TabPanel>
                        </div>
                        <div>
                            <TabPanel value="2">
                                <Grid container justifyContent={justify}>
                                    {components.trophies.table}
                                </Grid>
                            </TabPanel>
                        </div>
                        <div>
                            <TabPanel value="3">
                                {isMobile &&
                                    <table><tbody>{mobileViewTableRows.tabManager}</tbody></table>
                                }
                                {!isMobile &&
                                <Grid container justifyContent={justify} style={{columnGap: 40}}>
                                    <Grid item xs={5}><Box display="flex" justifyContent="flex-end">{components.managerPointsWeek.top.label}</Box></Grid>
                                    <Grid item xs={5}>{components.managerPointsWeek.bottom.label}</Grid>
                                    <Grid item xs={5}><Box display="flex" justifyContent="flex-end">{components.managerPointsWeek.top.grid}</Box></Grid>
                                    <Grid item xs={5}>{components.managerPointsWeek.bottom.grid}</Grid>
                                    <Grid item xs={5}><br/><br/><Box display="flex" justifyContent="flex-end">{components.managerPointsRegular.top.label}</Box></Grid>
                                    <Grid item xs={5}><br/><br/>{components.managerPointsRegular.bottom.label}</Grid>
                                    <Grid item xs={5}><Box display="flex" justifyContent="flex-end">{components.managerPointsRegular.top.grid}</Box></Grid>
                                    <Grid item xs={5}>{components.managerPointsRegular.bottom.grid}</Grid>
                                    <Grid item xs={5}><br/><br/><Box display="flex" justifyContent="flex-end">{components.managerPointsFull.top.label}</Box></Grid>
                                    <Grid item xs={5}><br/><br/>{components.managerPointsFull.bottom.label}</Grid>
                                    <Grid item xs={5}><Box display="flex" justifyContent="flex-end">{components.managerPointsFull.top.grid}</Box></Grid>
                                    <Grid item xs={5}>{components.managerPointsFull.bottom.grid}</Grid>
                                    <Grid item xs={5}><br/><br/><Box display="flex" justifyContent="flex-end">{components.managerAgainstRegular.top.label}</Box></Grid>
                                    <Grid item xs={5}><br/><br/>{components.managerAgainstRegular.bottom.label}</Grid>
                                    <Grid item xs={5}><Box display="flex" justifyContent="flex-end">{components.managerAgainstRegular.top.grid}</Box></Grid>
                                    <Grid item xs={5}>{components.managerAgainstRegular.bottom.grid}</Grid>
                                    <Grid item xs={5}><br/><br/><Box display="flex" justifyContent="flex-end">{components.managerAgainstFull.top.label}</Box></Grid>
                                    <Grid item xs={5}><br/><br/>{components.managerAgainstFull.bottom.label}</Grid>
                                    <Grid item xs={5}><Box display="flex" justifyContent="flex-end">{components.managerAgainstFull.top.grid}</Box></Grid>
                                    <Grid item xs={5}>{components.managerAgainstFull.bottom.grid}</Grid>
                                </Grid>}
                            </TabPanel>
                        </div>
                        <div>
                            <TabPanel value="4">
                                {isMobile &&
                                    <table><tbody>{mobileViewTableRows.tabPlayer}</tbody></table>
                                }
                                {!isMobile &&
                                <Grid container justifyContent={justify} style={{columnGap: 40}}>
                                    <Grid item xs={5}><Box display="flex" justifyContent="flex-end">{components.playerPointsWeek.top.label}</Box></Grid>
                                    <Grid item xs={5}>{components.playerPointsWeek.bottom.label}</Grid>
                                    <Grid item xs={5}><Box display="flex" justifyContent="flex-end">{components.playerPointsWeek.top.grid}</Box></Grid>
                                    <Grid item xs={5}>{components.playerPointsWeek.bottom.grid}</Grid>
                                </Grid>}
                            </TabPanel>
                        </div>
                        <div>
                            <TabPanel value="5">
                                {isMobile &&
                                    <table><tbody>{mobileViewTableRows.tabMatchup}</tbody></table>
                                }
                                {!isMobile &&
                                <Grid container justifyContent={justify} style={{columnGap: 40, maxWidth: 1400}}>
                                    <Grid item xs={5}><Box display="flex" justifyContent="flex-end">{components.matchupPointsDiff.top.label}</Box></Grid>
                                    <Grid item xs={5}>{components.matchupPointsDiff.bottom.label}</Grid>
                                    <Grid item xs={5}><Box display="flex" justifyContent="flex-end">{components.matchupPointsDiff.top.grid}</Box></Grid>
                                    <Grid item xs={5}>{components.matchupPointsDiff.bottom.grid}</Grid>
                                    <Grid item xs={12} style={{textAlign: "center"}}><br/><br/>{components.filthSummary.label}</Grid>
                                    <Grid item xs={12} style={{maxWidth: 1070}}>{components.filthSummary.grid}</Grid>
                                    <Grid item xs={5}><br/><br/><Box display="flex" justifyContent="flex-end">{components.dirtyClean.dirty.label}</Box></Grid>
                                    <Grid item xs={5}><br/><br/>{components.dirtyClean.clean.label}</Grid>
                                    <Grid item xs={5}><Box display="flex" justifyContent="flex-end">{components.dirtyClean.dirty.grid}</Box></Grid>
                                    <Grid item xs={5}>{components.dirtyClean.clean.grid}</Grid>
                                </Grid>}
                            </TabPanel>
                        </div>
                    </div>
                </TabContext>
            </Grid>
            <br/>
            <br/>
            <br/>
        </div>
    );
}