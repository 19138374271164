import React, {useCallback, useState, useEffect, useMemo} from 'react';
import {DataGrid} from "@mui/x-data-grid";
import {Button, Checkbox, Typography} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import {config} from "../config";
import {apiGet, rosterGridTypes} from "../utils";

export const RosterGrid = ({type, managerName, seasonYear, weekNum, currentAuction, showPoints, onDropButtonClick, onTradeCheckboxClick, rosterOverride, spentOverride, loadingOverride}) => {
    const [roster, setRoster] = useState([]);
    const [spent, setSpent] = useState(0);
    const [loading, setLoading] = useState(true);

    const sortRosterRows = useCallback((rows) => {
        rows.sort((a, b) => {
            if (!a.is_owned && b.is_owned) return 1;
            if (a.is_owned && !b.is_owned) return -1;
            if (!a.was_active && b.was_active) return 1;
            if (a.was_active && !b.was_active) return -1;
            if ((a.is_owned && b.is_owned) || (a.was_active && b.was_active)) {
                if (a.display_order > b.display_order) return 1;
                if (a.display_order < b.display_order) return -1;
                if (a.salary < b.salary) return 1;
                if (a.salary > b.salary) return -1;
            } else {
                if (a.signed_year > b.signed_year) return 1;
                if (a.signed_year < b.signed_year) return -1;
                if (a.penalty < b.penalty) return 1;
                if (a.penalty > b.penalty) return -1;
            }
            if (a.last_name > b.last_name) return 1;
            if (a.last_name < b.last_name) return -1;
            if (a.first_name > b.first_name) return 1;
            if (a.first_name < b.first_name) return -1;
            return 0;
        });
        renumberRosterRows(rows);
    }, []);

    const renumberRosterRows = (rows) => {
        let owned = 0;
        for (let i = 0; i < rows.length; i++) {
            if (rows[i].is_owned || rows[i].was_active) {
                rows[i].row_number = i + 1;
                owned++;
            } else {
                rows[i].row_number = i + 1 - owned;
            }
        }
    };

    const fetchRosterData = useCallback((year, week) => {
        setLoading(true);
        if (rosterOverride) {
            const rosterOverrideCopy = JSON.parse(JSON.stringify(rosterOverride));
            sortRosterRows(rosterOverrideCopy);
            setRoster(rosterOverrideCopy);
            setSpent(spentOverride);
            setLoading(false);
        } else {
            if (year && week) {
                if (!(year === currentAuction.season_year && week === currentAuction.week_num)) {
                    apiGet(`roster/manager/${managerName}/season/${year}/week/${week}`, (data) => {
                        if (data.data) {
                            setRoster(data.data);
                            setSpent(data.data.map(c => c.salary ? c.salary : c.penalty).reduce((a, b) => a + b));
                            setLoading(false);
                        }
                    });
                } else {
                    apiGet(`contract/owned/${managerName}${type === rosterGridTypes.PROJECTED? '/projected' : ''}`, (ownedData) => {
                        apiGet(`contract/penalty/${managerName}${type === rosterGridTypes.PROJECTED? '/projected' : ''}`, (penaltyData) => {
                            if (penaltyData.data) {
                                setRoster(penaltyData.data ? [...ownedData.data, ...penaltyData.data] : ownedData.data);
                                setSpent((ownedData.data ? ownedData.data.map(contract => contract.salary).reduce((a, b) => a + b) : 0) +
                                    (penaltyData.data ? penaltyData.data.map(contract => contract.penalty).reduce((a, b) => a + b) : 0));
                            }
                            setLoading(false);
                        });
                    });
                }
            }
        }
    }, [type, managerName, currentAuction, rosterOverride, spentOverride, sortRosterRows]);

    const columnModels = useMemo(() => {
        const renderSalaryAmount = (params) => {
            return params.row.salary ? params.row.salary : '';  //TODO: if pre-draft, display RFA instead of blank
        };

        const renderDropPenalty = (params) => {
            return params.row.penalty > 0 ? params.row.penalty : ' ';
        };

        const renderProjectedDropPenalty = (params) => {
            return params.row.penalty_next_year > 0 ? params.row.penalty_next_year : ' ';
        };

        const renderDropButton = (params) => {
            if (params.row.dropped_year) {
                return (<div/>);
            } else {
                const onClick = async () => {
                    onDropButtonClick(params.row);
                }
                return (
                    <Button color="primary" onClick={onClick} style={{padding: 0}}>Drop</Button>
                )
            }
        };

        const renderTradeCheckbox = (params) => {
            //TODO: DataGrid column def could have checkboxSelection property...?
            const handleChange = async (event) => {
                onTradeCheckboxClick(params.row, event.target.checked);
            }
            return (
                <Checkbox onChange={handleChange} style={{padding: 0}}/>
            )
        };

        const renderFantasyPoints = (params) => {
            return params.row.started ? <b>{params.row.points}</b> : params.row.points;
        };

        const colDefs = {
            rosterColumns: [
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 65, headerName: 'Num', field: 'row_number', type: 'number', headerAlign: 'right'},
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 130, headerName: 'First Name', field: 'first_name'},
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 130, headerName: 'Last Name', field: 'last_name'},
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 60, headerName: 'Pos', field: 'pos', headerAlign: 'center', align: 'center'},
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 70, headerName: 'Team', field: 'team', headerAlign: 'center', align: 'center'},
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 70, headerName: 'Year', field: 'signed_year', headerAlign: 'center', align: 'center'}
            ],
            normalMoneyColumns: [
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 65, headerName: 'Salary', field: 'salary', type: 'number', headerAlign: 'right', renderCell: renderSalaryAmount},
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 70, headerName: 'Penalty', field: 'penalty', type: 'number', headerAlign: 'right', renderCell: renderDropPenalty}
            ],
            projectedMoneyColumns: [
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 110, headerName: 'New Salary', field: 'salary', type: 'number', headerAlign: 'right', renderCell: renderSalaryAmount},
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 90, headerName: 'Drop Pre', field: 'penalty', type: 'number', headerAlign: 'right', renderCell: renderDropPenalty},
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 100, headerName: 'Drop Post', field: 'penalty_next_year', type: 'number', headerAlign: 'right', renderCell: renderProjectedDropPenalty}
            ],
            actionColumns: [
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 85, headerName: ' ', field: 'drop', align: 'center', renderCell: renderDropButton}
            ],
            tradeColumns: [
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 60, headerName: ' ', field: 'trade', align: 'center', renderCell: renderTradeCheckbox}
            ],
            pointsColumns: [
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 135, headerName: 'Fantasy Points', field: 'points', type: 'number', headerAlign: 'right', renderCell: renderFantasyPoints}
            ]
        };

        const calcWidth = (cols) => {
            return cols.reduce((t, c) => t + c.width, 0) + 1;
        };

        const salariesModel = [...colDefs.rosterColumns, ...colDefs.normalMoneyColumns];
        const pointsModel = [...colDefs.rosterColumns, ...colDefs.pointsColumns];
        const projectedModel = [...colDefs.rosterColumns, ...colDefs.projectedMoneyColumns];
        const tradingModel = [...colDefs.rosterColumns, ...colDefs.normalMoneyColumns, ...colDefs.tradeColumns];
        const actionsModel = [...colDefs.rosterColumns, ...colDefs.normalMoneyColumns, ...colDefs.actionColumns];

        return {
            salaries: {cols: salariesModel, width: calcWidth(salariesModel)},
            points: {cols: pointsModel, width: calcWidth(pointsModel)},
            projected: {cols: projectedModel, width: calcWidth(projectedModel)},
            trading: {cols: tradingModel, width: calcWidth(tradingModel)},
            actions: {cols: actionsModel, width: calcWidth(actionsModel)}
        };
    }, [onDropButtonClick, onTradeCheckboxClick]);

    const displayColumns = useMemo(() => {
        if (type === rosterGridTypes.STANDARD) {
            return showPoints ? columnModels.points : columnModels.salaries;
        } else {
            return columnModels[type];
        }
    }, [type, columnModels, showPoints]);

    useEffect(() => {
        fetchRosterData(seasonYear, weekNum);
    }, [fetchRosterData, seasonYear, weekNum]);

    useEffect(() => {
        setLoading(loadingOverride);
    }, [loadingOverride]);

    const determineRowClass = (params) => {
        let classes;
        if ('is_penalty' in params.row) {
            classes = `${params.row.is_penalty ? 'penalty' : params.row.display_order % 2 === 1 ? 'table-row-odd' : 'table-row-even'}`;
            classes += params.row.proposed ? ' proposed-trade' : '';
        } else {
            classes = `${!params.row.is_owned ? 'penalty' : params.row.display_order % 2 === 1 ? 'table-row-odd' : 'table-row-even'}`;
            classes += params.row.transaction_key ? '' : ' needs-yahoo-sync';
            classes += params.row.proposed ? ' proposed-trade' : '';
            classes += params.row.salary ? '' : ' is-rfa';
        }
        return classes;
    };

    const determineOverRosterSize = (roster) => {
        const numOwned = roster ? roster.reduce((total, curr) => curr.is_penalty ? total : total + 1, 0) : 0;
        return numOwned > config.maxRosterSize;
    };

    const determineOverBudget = (spent) => {
        return spent > config.salaryCap;
    };

    const calculateFantasyPointsTotal = (roster) => {
        return roster ? roster.reduce((total, curr) => curr.started ? total + parseFloat(curr.points) : total, 0) : 0;
    };

    return (
        <div style={{width: displayColumns.width, marginLeft: 10, marginRight: 10}}>
            {loading && <LinearProgress color="secondary"/>}
            <DataGrid columns={displayColumns.cols} rows={roster} hideFooterPagination={true}
                      columnHeaderHeight={30} rowHeight={23} autoHeight
                      getRowClassName={(params) => (determineRowClass(params))}
                      disableRowSelectionOnClick disableMultipleRowSelection disableColumnResize
                      slots={{footer: RosterGridFooter}} slotProps={{
                footer: {
                    spent: spent,
                    isOverRosterSize: determineOverRosterSize(roster),
                    isOverBudget: determineOverBudget(spent),
                    pointsMode: showPoints,
                    fantasyPoints: calculateFantasyPointsTotal(roster)
                }
            }}/>
        </div>
    );
}

export const RosterGridFooter = ({spent, isOverRosterSize, isOverBudget, pointsMode, fantasyPoints}) => {
    return (
        <div>
            <div style={{padding: 20, float: 'left', textAlign: 'left'}}>
                {isOverRosterSize && <Typography variant="h5" color="error">TOO MANY PLAYERS</Typography>}
                {isOverBudget && <Typography variant="h5" color="error">OVER BUDGET</Typography>}
            </div>
            {spent &&
                <div style={{padding: 20, float: 'right', textAlign: 'right'}}>
                    {!pointsMode ?
                        <div>
                            <Typography variant="h6" color="inherit">Used: {spent}</Typography>
                            <Typography variant="h6" color="inherit">Available: {config.salaryCap - spent}</Typography>
                        </div>
                        :
                        <div>
                            <Typography variant="h6" color="inherit">Total: {fantasyPoints.toFixed(2)}</Typography>
                            <div style={{minHeight: 32}}/>
                        </div>
                    }
                </div>
            }
        </div>
    );
}