import React, {useEffect, useState} from "react";
import {useHistory} from 'react-router-dom';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {apiPost, login, logout} from "../utils";
import Grid from "@material-ui/core/Grid";

export const LoginContainer = ({setManagerName}) => {
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        logout();
    }, []);

    const onEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const onPasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const onSubmit = (event) => {
        event.preventDefault();
        const loginPostBody = {
            email: email,
            password: password //TODO: hash...
        }
        apiPost(`auth/login`, loginPostBody, (data) => {
            login(data);
            history.push('/rosters');
            setManagerName('LOADING...');
        });
    };

    return (
        <div style={{marginTop: 100}}>
            <Grid container justifyContent="center">
                <TextField id="emailField" type="email" label="Email" value={email} required autoFocus
                           onChange={onEmailChange}/>
            </Grid>
            <Grid container justifyContent="center">
                <TextField id="passwordField" type="password" label="Password" value={password} required
                           onChange={onPasswordChange}/>
            </Grid>
            <Grid container justifyContent="center">
                <Button variant="outlined" type="submit" color="primary" style={{marginTop: 30}}
                        onClick={onSubmit}>Submit</Button>
            </Grid>
        </div>
    );
}