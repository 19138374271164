import React, {useMemo} from 'react';
import {DataGrid} from "@mui/x-data-grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import {getUserName} from "../utils";

export const DirtyCleanSummaryGrid = ({rows, loading}) => {

    const columnModels = useMemo(() => {
        const colDefs = {
            defaultColumns: [
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 80, headerName: 'Manager', field: 'manager_name'},
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 140, headerName: 'Dirty Reitzer', field: 'dirty_reitzer', type: 'number', headerAlign: 'right'},
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 140, headerName: 'Dirty Reitzed', field: 'dirty_reitzed', type: 'number', headerAlign: 'right'},
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 140, headerName: 'Clean Randaller', field: 'clean_randaller', type: 'number', headerAlign: 'right'},
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 140, headerName: 'Clean Randalled', field: 'clean_randalled', type: 'number', headerAlign: 'right'},
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 140, headerName: 'Times Being -er', field: 'times_being_er', type: 'number', headerAlign: 'right'},
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 140, headerName: 'Times Being -ed', field: 'times_being_ed', type: 'number', headerAlign: 'right'},
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 150, headerName: 'Overall Filth Index', field: 'filth_index', type: 'number', headerAlign: 'right'}
            ]
        };

        const calcWidth = (cols) => {
            return cols.reduce((t, c) => t + c.width, 0) + 1;
        };

        const defaultModel = [...colDefs.defaultColumns];

        return {default: {cols: defaultModel, width: calcWidth(defaultModel)}};
    }, []);

    const displayColumns = useMemo(() => {
        return columnModels.default;
    }, [columnModels]);

    const determineRowClass = (params) => {
        return params.row.manager_name === getUserName() ? 'is-self' : '';
    };

    return (
        <div style={{width: displayColumns.width, marginLeft: 10, marginRight: 10}}>
            {loading && <LinearProgress color="secondary"/>}
            <DataGrid columns={displayColumns.cols} rows={rows} hideFooter={true}
                      columnHeaderHeight={30} rowHeight={23} autoHeight getRowId={r => r.row_id}
                      getRowClassName={(params) => (determineRowClass(params))}
                      disableRowSelectionOnClick disableMultipleRowSelection disableColumnResize/>
        </div>
    );
}
