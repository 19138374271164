import React, {useCallback, useState} from 'react';
import {StatsGrid} from "../components/StatsGrid";
import Grid from "@material-ui/core/Grid";
import {useMediaQuery} from "@mui/material";
import {ManagerPickerMultiselect} from "../components/ManagerPickerMultiselect";
import {apiGet} from "../utils";

export const StatsContainer = () => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);

    const isMobile = useMediaQuery('(max-width: 1550px)');

    const fetchRosterData = useCallback((managerNames) => {
        if (managerNames && managerNames.length > 0) {
            setLoading(true);
            apiGet(`stats/owned/managers/${managerNames.join(',')}`, (data) => {
                setRows(data.data);
                setLoading(false);
            });
        }
    }, []);

    const justify = isMobile ? 'flex-start' : 'center';
    return (
        <div>
            <Grid container justifyContent={justify}>
                <table>
                    <tbody>
                    <tr>
                        <td align="right">
                            <ManagerPickerMultiselect fetchFunction={fetchRosterData}/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {(rows.length > 0 || loading) &&
                                <div style={{marginTop: 15}}>
                                    <StatsGrid rows={rows} loading={loading}/>
                                </div>}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </Grid>
            <br/>
            <br/>
        </div>
    );
}