import React, {useCallback, useMemo, useState, useEffect} from 'react';
import {DataGrid} from "@mui/x-data-grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import {apiGet, getUserName, recordListGridTypes} from "../utils";

export const RecordListGrid = ({type, managerPlayerMatchup, pointsOrAgainst, weekRegularFull, dirtyOrClean, topOrBottom}) => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchRecordData = useCallback(() => {
        setLoading(true);
        let queryPath;
        if (type === recordListGridTypes.STANDARD || type === recordListGridTypes.WITH_WEEK || type === recordListGridTypes.WITH_WEEK_AND_PLAYER) {
            queryPath = `ten/${managerPlayerMatchup}/${pointsOrAgainst}/${weekRegularFull}/${topOrBottom}`;
        } else if (type === recordListGridTypes.MATCHUP_DIFF) {
            queryPath = `ten/${managerPlayerMatchup}/difference/${topOrBottom}`;
        } else if (type === recordListGridTypes.DIRTY_CLEAN) {
            queryPath = `${dirtyOrClean}/all`;
        }
        apiGet(`record/${queryPath}`, (data) => {
            setRows(data.data);
            setLoading(false);
        });
    }, [type, managerPlayerMatchup, pointsOrAgainst, weekRegularFull, dirtyOrClean, topOrBottom]);

    const columnModels = useMemo(() => {
        const pointsField = `${weekRegularFull === 'regular' ? 'reg_season_' : weekRegularFull === 'full' ? 'full_season_' : ''}${pointsOrAgainst === 'points' ? 'total_points' : 'points_against'}`;
        const colDefs = {
            rankCol: [
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 80, headerName: 'Rank', field: 'rank'}
            ],
            defaultColumns: [
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 80, headerName: 'Year', field: 'year'},
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 80, headerName: 'Manager', field: 'manager_name'},
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 80, headerName: 'Points', field: pointsField, type: 'number', headerAlign: 'right'}
            ],
            withWeek: [
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 80, headerName: 'Year', field: 'year'},
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 80, headerName: 'Week', field: 'week'},
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 80, headerName: 'Manager', field: 'manager_name'},
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 80, headerName: 'Points', field: 'total_points', type: 'number', headerAlign: 'right'}
            ],
            withWeekAndPlayer: [
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 80, headerName: 'Year', field: 'year'},
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 80, headerName: 'Week', field: 'week'},
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 80, headerName: 'Manager', field: 'manager_name'},
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 160, headerName: 'Player', field: 'player_name'},
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 60, headerName: 'Pos', field: 'pos'},
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 80, headerName: 'Salary', field: 'salary', type: 'number', headerAlign: 'right'},
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 80, headerName: 'Points', field: 'points', type: 'number', headerAlign: 'right'},
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 80, headerName: 'Started', field: 'started', headerAlign: 'center', align: 'center',
                    renderCell: p => p.row.started ? 'Yes' : 'No'}
            ],
            opponentCols: [
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 80, headerName: 'Manager', field: 'opponent_name'},
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 80, headerName: 'Points', field: 'opp_total_points', type: 'number', headerAlign: 'right'}
            ],
            diffCol: [
                {sortable: false, disableColumnMenu: true, headerClassName: 'grid-header', width: 100, headerName: 'Difference', field: 'diff', type: 'number', headerAlign: 'right'}
            ]
        };

        const calcWidth = (cols) => {
            return cols.reduce((t, c) => t + c.width, 0) + 1;
        };

        const defaultModel = [...colDefs.rankCol, ...colDefs.defaultColumns];
        const withWeekModel = [...colDefs.rankCol, ...colDefs.withWeek];
        const withWeekAndPlayerModel = [...colDefs.rankCol, ...colDefs.withWeekAndPlayer];
        const matchupDiffModel = [...colDefs.rankCol, ...colDefs.withWeek, ...colDefs.opponentCols, ...colDefs.diffCol];
        const dirtyCleanModel = [...colDefs.withWeek, ...colDefs.opponentCols, ...colDefs.diffCol];

        return {
            standard: {cols: defaultModel, width: calcWidth(defaultModel)},
            withWeek: {cols: withWeekModel, width: calcWidth(withWeekModel)},
            withWeekAndPlayer: {cols: withWeekAndPlayerModel, width: calcWidth(withWeekAndPlayerModel)},
            matchupDiff: {cols: matchupDiffModel, width: calcWidth(matchupDiffModel)},
            dirtyClean: {cols: dirtyCleanModel, width: calcWidth(dirtyCleanModel)}
        };
    }, [pointsOrAgainst, weekRegularFull]);

    const displayColumns = useMemo(() => {
        return columnModels[type];
    }, [type, columnModels]);

    useEffect(() => {
        fetchRecordData();
    }, [fetchRecordData]);

    const determineRowClass = (params) => {
        if (type === recordListGridTypes.STANDARD || type === recordListGridTypes.WITH_WEEK || type === recordListGridTypes.WITH_WEEK_AND_PLAYER) {
            return params.row.manager_name === getUserName() ? 'is-self' : '';
        } else if (type === recordListGridTypes.MATCHUP_DIFF || type === recordListGridTypes.DIRTY_CLEAN) {
            return params.row.manager_name === getUserName() ? 'is-self' : params.row.opponent_name === getUserName() ? 'is-self' : '';
        }
    };

    return (
        <div style={{width: displayColumns.width, marginLeft: 10, marginRight: 10}}>
            {loading && <LinearProgress color="secondary"/>}
            <DataGrid columns={displayColumns.cols} rows={rows} hideFooter
                      columnHeaderHeight={30} rowHeight={23} autoHeight getRowId={r => r.row_id}
                      getRowClassName={(params) => (determineRowClass(params))}
                      disableRowSelectionOnClick disableMultipleRowSelection disableColumnResize/>
        </div>
    );
}
