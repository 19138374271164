import React, {useState, useEffect} from 'react';
import {FormControlLabel, Radio, RadioGroup, Typography} from "@material-ui/core";
import {config} from '../config';
import {WeekPicker} from "../components/WeekPicker";
import {RosterGrid} from '../components/RosterGrid';
import Grid from "@material-ui/core/Grid";
import {useMediaQuery} from "@mui/material";
import {apiGet, rosterGridTypes} from "../utils";

const managerNames = config.managerNames;

export const SpreadsheetContainer = () => {
    const [currentAuction, setCurrentAuction] = useState({});
    const [seasonYear, setSeasonYear] = useState(null);
    const [weekNum, setWeekNum] = useState(null);
    const [pointsMode, setPointsMode] = useState(false);

    const isMobile = useMediaQuery('(max-width: 1420px)');

    const setYearAndWeek = (year, week) => {
        setSeasonYear(year);
        setWeekNum(week);
    };

    useEffect(() => {
        apiGet(`auction/current`, (data) => {
            setCurrentAuction(data.data);
            setYearAndWeek(data.data.season_year, data.data.week_num);
        });
    }, []);

    const handleModeChange = (event) => {
        setPointsMode(event.target.value === 'points');
    };

    const tableRows = [];
    if (isMobile) {
        for (let i = 0; i < 8; i++) {
            tableRows.push(
                <tr key={`tr${i}t`}>
                    <td><Typography variant="h6" style={{margin: 10}}>{managerNames[i]}</Typography></td>
                </tr>);
            tableRows.push(
                <tr key={`tr${i}b`}>
                    <td style={{verticalAlign: 'top'}}>
                        <RosterGrid type={rosterGridTypes.STANDARD} managerName={managerNames[i]}
                                    seasonYear={seasonYear} weekNum={weekNum} currentAuction={currentAuction}
                                    showPoints={pointsMode}/>
                    </td>
                </tr>)
        }
    } else {
        for (let i = 0; i < 8; i += 2) {
            tableRows.push(
                <tr key={`tr${i}`}>
                    <td><Typography variant="h6" style={{margin: 10}}>{managerNames[i]}</Typography></td>
                    <td><Typography variant="h6" style={{margin: 10}}>{managerNames[i + 1]}</Typography></td>
                </tr>);
            tableRows.push(
                <tr key={`tr${i + 1}`}>
                    <td style={{verticalAlign: 'top'}}>
                        <RosterGrid type={rosterGridTypes.STANDARD} managerName={managerNames[i]}
                                    seasonYear={seasonYear} weekNum={weekNum} currentAuction={currentAuction}
                                    showPoints={pointsMode}/>
                    </td>
                    <td style={{verticalAlign: 'top'}}>
                        <RosterGrid type={rosterGridTypes.STANDARD} managerName={managerNames[i + 1]}
                                    seasonYear={seasonYear} weekNum={weekNum} currentAuction={currentAuction}
                                    showPoints={pointsMode}/>
                    </td>
                </tr>)
        }
    }

    const justify = isMobile ? 'flex-start' : 'center';
    return (
        <div>
            <Grid container justifyContent={justify}>
                <table>
                    <tbody>
                    <tr>
                        {!isMobile && <td/>}
                        <td align="right">
                            <WeekPicker setterFunction={setYearAndWeek} currentAuction={currentAuction} includeDraft={false}/>
                            <RadioGroup defaultValue="salaries" row onChange={handleModeChange}
                                        style={{float: "right"}}>
                                <FormControlLabel value="salaries" control={<Radio/>} label="Salaries"/>
                                <FormControlLabel value="points" control={<Radio/>} label="Points"/>
                            </RadioGroup>
                        </td>
                    </tr>
                    {tableRows}
                    <tr>
                        <td style={{height: 100}}/>
                        <td style={{height: 100}}/>
                    </tr>
                    </tbody>
                </table>
            </Grid>
        </div>
    );
}