import React, {useMemo} from 'react';
import {DataGrid} from "@mui/x-data-grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import {config} from "../config";

const managerNames = config.managerNames;

export const StatsGrid = ({rows, loading}) => {

    const columnModels = useMemo(() => {
        const colDefs = {
            rosterColumns: [
                {hideable: false, headerClassName: 'grid-header', width: 135, headerName: 'Manager', field: 'manager_name'},
                {hideable: false, headerClassName: 'grid-header', width: 160, headerName: 'First Name', field: 'first_name'},
                {hideable: false, headerClassName: 'grid-header', width: 160, headerName: 'Last Name', field: 'last_name'},
                {hideable: false, headerClassName: 'grid-header', width: 105, headerName: 'Pos', field: 'pos', headerAlign: 'center', align: 'center'},
                {hideable: false, headerClassName: 'grid-header', width: 110, headerName: 'Team', field: 'team', headerAlign: 'center', align: 'center'},
                {hideable: false, headerClassName: 'grid-header', width: 105, headerName: 'Year', field: 'signed_year', headerAlign: 'center', align: 'center'},
                {hideable: false, headerClassName: 'grid-header', width: 120, headerName: 'Salary', field: 'salary', type: 'number', headerAlign: 'right'}
            ],
            statColumns: [
                {hideable: false, headerClassName: 'grid-header', width: 170, headerName: 'Games Played', field: 'games_played', type: 'number', headerAlign: 'right', description: 'Games played this season (scoring only) under this contract'},
                {hideable: false, headerClassName: 'grid-header', width: 150, headerName: 'Total Points', field: 'total_points', type: 'number', headerAlign: 'right', description: 'Fantasy points this season under this contract'},
                {hideable: false, headerClassName: 'grid-header', width: 130, headerName: 'Points/$', field: 'points_per_dollar', type: 'number', headerAlign: 'right', description: 'Fantasy points per salary dollar this season under this contract'},
                {hideable: false, headerClassName: 'grid-header', width: 170, headerName: 'Points/$/Game', field: 'points_per_dollar_per_game_played', type: 'number', headerAlign: 'right', description: 'ROI per game (in points/$) this season under this contract'}
            ]
        };

        const calcWidth = (cols) => {
            return cols.reduce((t, c) => t + c.width, 0) + 1;
        };

        const defaultModel = [...colDefs.rosterColumns, ...colDefs.statColumns];

        return {default: {cols: defaultModel, width: calcWidth(defaultModel)}};
    }, []);

    const displayColumns = useMemo(() => {
        return columnModels.default;
    }, [columnModels]);

    const determineRowClass = (params) => {
        return `manager-color-${managerNames.indexOf(params.row.manager_name) + 1}-light`;
    };

    return (
        <div style={{width: displayColumns.width, marginLeft: 10, marginRight: 10}}>
            {loading && <LinearProgress color="secondary"/>}
            <DataGrid columns={displayColumns.cols} rows={rows} pageSizeOptions={[25, 50, 100]}
                      initialState={{pagination: {paginationModel: {pageSize: 50, page: 0}}}}
                      columnHeaderHeight={30} rowHeight={23} autoHeight getRowId={r => r.row_id}
                      getRowClassName={(params) => (determineRowClass(params))}
                      disableRowSelectionOnClick disableMultipleRowSelection disableColumnResize/>
        </div>
    );
}
