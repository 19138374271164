import React, {useState, useEffect} from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import {config} from "../config";
import {IconButton} from "@material-ui/core";
import {NavigateBefore, NavigateNext, RotateLeft} from "@material-ui/icons";

export const SeasonPicker = ({setterFunction, currentAuction, seasonYearsOverride}) => {
    const [seasonYears, setSeasonYears] = useState(config.seasonYears);
    const [anchorElSeason, setAnchorElSeason] = useState(null);
    const [selectedSeasonIndex, setSelectedSeasonIndex] = useState(null);

    useEffect(() => {
        if (seasonYearsOverride) {
            setSeasonYears(seasonYearsOverride);
        }
    }, [seasonYearsOverride]);

    useEffect(() => {
        setSelectedSeasonIndex(seasonYears.indexOf(currentAuction.season_year));
    }, [seasonYears, currentAuction.season_year]);

    const handleClickSeason = (event) => {
        setAnchorElSeason(event.currentTarget);
    };

    const handleSeasonMenuItemClick = (event, index) => {
        setSelectedSeasonIndex(index);
        setAnchorElSeason(null);
    };

    const handleClose = () => {
        setAnchorElSeason(null);
    };

    const handlePreviousSeason = () => {
        if (selectedSeasonIndex !== 0) {
            setterFunction(seasonYears[selectedSeasonIndex - 1]);
            setSelectedSeasonIndex(selectedSeasonIndex - 1);
        }
    };

    const handleNextSeason = () => {
        if (selectedSeasonIndex !== seasonYears.indexOf(currentAuction.season_year)) {
            setterFunction(seasonYears[selectedSeasonIndex + 1]);
            setSelectedSeasonIndex(selectedSeasonIndex + 1);
        }
    };

    const handleSubmit = () => {
        setterFunction(seasonYears[selectedSeasonIndex]);
    };

    const handleReset = () => {
        setSelectedSeasonIndex(seasonYears.indexOf(currentAuction.season_year));
        setterFunction(currentAuction.season_year);
    };

    return (
        <div style={{paddingTop: 15, paddingBottom: 15}}>
            <IconButton color="inherit" style={{height: 25}} onClick={handlePreviousSeason}>
                <NavigateBefore/>
            </IconButton>
            <IconButton color="inherit" style={{height: 25}} onClick={handleNextSeason}>
                <NavigateNext/>
            </IconButton>
            Season:
            <Button style={{backgroundColor: "lightgray", height: 25, marginLeft: 10, marginRight: 10}}
                    onClick={handleClickSeason}>{seasonYears[selectedSeasonIndex]}</Button>
            <Menu id="seasonPickerMenu" anchorEl={anchorElSeason} keepMounted open={Boolean(anchorElSeason)}
                  onClose={handleClose}>
                {seasonYears.map((seasonYear, index) => (
                    <MenuItem key={seasonYear} selected={index === selectedSeasonIndex}
                              onClick={(event) => handleSeasonMenuItemClick(event, index)}>{seasonYear}</MenuItem>
                ))}
            </Menu>
            <Button variant="contained" color="primary" style={{height: 25}} onClick={handleSubmit}>Select</Button>
            <IconButton color="inherit" style={{height: 25}} onClick={handleReset}>
                <RotateLeft/>
            </IconButton>
        </div>
    );
}